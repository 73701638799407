import { useState } from "react";

export const useSideNav = () => {
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    return {
        open,
        toggleDrawer
    }
}