import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';
import { useService } from '../../hooks/useService';
import dayjs from 'dayjs';

import { StaticDateTimePicker } from '@mui/x-date-pickers'
import DeleteIcon from '@mui/icons-material/Delete';
import {
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
    Autocomplete,
} from '@mui/material'

import { paperworkServiceFactory } from '../../services/paperworkService';
import { usePaperworkContext } from '../../contexts/PaperworkContext';

export const DeletePaperwork = () => {
    const { paperworkId } = useParams();
    const paperworkService = useService(paperworkServiceFactory);
    const { paperworkAlertMessage, setPaperworkAlertMessage, deletePaperwork } = usePaperworkContext();

    const initialValues = {
        title: '',
        description: '',
        expiryDate: '',
        type: '',
    };

    const [formErrors, setFormErrors] = useState({
        title: '',
        description: '',
        expiryDate: '',
        type: '',
    });

    const { values, changeHandler, onSubmit, changeValues } = useForm(initialValues, deletePaperwork);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    useEffect(() => {
        paperworkService.getOne(paperworkId)
            .then(result => {
                changeValues({ ...result, expiryDate: dayjs(result?.dateTime), });
            });
        // eslint-disable-next-line
    }, [paperworkId]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <DeleteIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Delete Paperwork
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.title}
                        margin="normal"
                        required
                        disabled
                        error={formErrors.title}
                        helperText={formErrors.title}
                        fullWidth
                        id="title"
                        label="Title"
                        name="title"
                        autoComplete="name"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.description}
                        margin="normal"
                        required
                        disabled
                        error={formErrors.description}
                        helperText={formErrors.description}
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete="description"
                    />

                    <Autocomplete
                        // onChange={changeHandler}
                        value={values.type}
                        onChange={(event, newValue) => {
                            values.type = newValue
                        }}
                        // inputValue={inputValue}
                        // onInputChange={(event, newInputValue) => {
                        //     setInputValue(newInputValue);
                        // }}
                        fullWidth
                        required
                        disabled
                        disablePortal
                        id="type"
                        label="Type"
                        name="type"
                        autoComplete="type"
                        options={["DBS Check", "Insurance"]}
                        renderInput={(params) => <TextField
                            margin='normal'
                            {...params}
                            label="Type *"
                        />}
                    />

                    <StaticDateTimePicker
                        // maxDate={new Date('future')}
                        // minDate={new Date('nearFuture')}
                        onChange={(newValue, changeHandler) => {
                            values.expiryDate = newValue?.$d
                        }}
                        value={values.expiryDate}
                        disabled
                        minutesStep={5}
                        slots={{
                            actionBar: 'div',
                        }}
                        disablePast={true}
                    />

                    {paperworkAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setPaperworkAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {paperworkAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                </Box>
            </Box>
        </Container >
    );
};