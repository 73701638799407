import { createContext, useContext } from 'react'
import { useSession, useSupabaseClient, useSessionContext } from "@supabase/auth-helpers-react";

import { addHours } from "../utils/dateUtils";
import { admins } from "../utils/mappers";

export const AuthContext = createContext();

export const AuthProvider = ({
    children,
}) => {
    // const [auth, setAuth] = useLocalStorage('auth', {});
    // const authService = authServiceFactory(auth.accessToken);
    // const navigate = useNavigate();
    const session = useSession();
    const supabase = useSupabaseClient();
    const { isLoading } = useSessionContext();

    async function handleSignInWithGoogle(response) {
        const { data, error } = await supabase.auth.signInWithIdToken({
            token: response.credential,
            nonce: '', // must be the same one as provided in data-nonce (if any)
        })
        console.log(data, error)
    }

    async function googleSignIn() {
        const { error } = await supabase.auth.signInWithOAuth({
            provider: 'google',
            options: {
                scopes: "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/drive.file https://www.googleapis.com/auth/drive.readonly"
            }
        });
        if (error) {
            alert('Error logging in to Google provider with Supabase');
            console.log(error);
        };
    };

    async function signOut() {
        await supabase.auth.signOut();
    }

    // const onLoginSubmit = async (data) => {
    //     try {
    //         const result = await authService.login(data);

    //         setAuth(result);

    //         navigate('/commutes');
    //     } catch (error) {
    //         return alert(error.message);
    //     };
    // };

    // const onRegisterSubmit = async (values) => {
    //     const {confirmPassword , ...registerData } = values;
    //     if (confirmPassword !== registerData.password) {
    //         return;
    //     };

    //     try {
    //         const result = await authService.register(registerData);

    //         setAuth(result);            

    //         navigate('/commutes');
    //     } catch (error) {
    //         return alert(error.message);
    //     };
    // };

    // const onLogout = async () => {
    //     await authService.logout();

    //     setAuth({});
    // };

    const context = {
        // onLoginSubmit,
        // onRegisterSubmit,
        // onLogout,
        // userId: auth._id,
        // token: auth.accessToken,
        // userEmail: auth.email,
        // isAuthenticated: !!auth.accessToken,
        session,
        supabase,
        isLoading,
        googleSignIn,
        handleSignInWithGoogle,
        signOut,
        isAuthenticated: !!session
    };

    return (
        <>
            <AuthContext.Provider value={context}>
                {children}
            </AuthContext.Provider>
        </>
    );
};

export const useAuthContext = () => {
    const context = useContext(AuthContext);
    const isAdmin = admins.includes(context?.session?.user?.email);
    const sessionExpiry = addHours(new Date(context?.session?.user.last_sign_in_at), 1);

    return {
        ...context,
        isAdmin,
        sessionExpiry
    }
};