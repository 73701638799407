import { Navigate, Outlet } from "react-router-dom";

import { useEventContext } from '../../contexts/EventContext';

export const SessionGuard = ({
    children,
}) => {
    const { session } = useEventContext();

    if (session) {
        return <Navigate to='/' />
    }

    return children ? children : <Outlet />
};