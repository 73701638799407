import { Routes, Route } from 'react-router-dom';

import { LocalizationProvider } from '@mui/x-date-pickers';
import { ServiceProvider } from './contexts/ServiceContext';
import { EventProvider } from './contexts/EventContext';
import { ClientProvider } from './contexts/ClientContext';
import { WorkerProvider } from './contexts/WorkerContext';
import { QuoteProvider } from './contexts/QuoteContext';
import { ItemProvider } from './contexts/ItemContext';
import { InvoiceProvider } from './contexts/InvoiceContext';
import { PaperworkProvider } from './contexts/PaperworkContext';
import { VehicleProvider } from './contexts/VehicleContext';

import { useAuthContext } from './contexts/AuthContext';
import { useSideNav } from './hooks/useSideNav';

import { SessionGuard } from './components/common/SessionGuard'
import { RouteGuard } from './components/common/RouteGuard';

import {
  Badge,
  Box,
  IconButton,
  Toolbar,
  Typography
} from '@mui/material'

import { AppBar } from './components/AppBar/AppBar';
import { Drawer } from './components/Drawer/Drawer';
import { ListItems } from './components/ListItems/ListItems';

import NotificationsIcon from '@mui/icons-material/Notifications';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Divider from '@mui/material/Divider';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import "./App.css";
import Dashboard from './components/Dashboard/Dashboard';
import { Home } from './components/Home/Home';
import { Navigation } from './components/Navigation/Navigation';
import { Logout } from './components/Logout/Logout';
import { Login } from './components/Login/Login';
import { Schedule } from './components/Schedule/Schedule';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ApproveQuote } from './components/Approve/ApproveQuote';
import { Footer } from './components/Footer/Footer';

import { EventTable } from './components/Table/EventTable';
import { WorkerTable } from './components/Table/WorkerTable';
import { ClientTable } from './components/Table/ClientTable';
import { ServiceTable } from './components/Table/ServiceTable';
import { InvoiceTable } from './components/Table/InvoiceTable';
import { QuoteTable } from './components/Table/QuoteTable';
import { ItemTable } from './components/Table/ItemTable';
import { PaperworkTable } from './components/Table/PaperworkTable';
import { VehicleTable } from './components/Table/VehicleTable';

import { Quote } from './components/Create/Quote';
import { Service } from './components/Create/Service';
import { Client } from './components/Create/Client';
import { Event } from './components/Create//Event';
import { Invoice } from './components/Create//Invoice';
import { Worker } from './components/Create/Worker';
import { Item } from './components/Create/Item';
import { Paperwork } from './components/Create/Paperwork';
import { Vehicle } from './components/Create/Vehicle';

import { EditQuote } from './components/Edit/EditQuote';
import { EditEvent } from './components/Edit/EditEvent';
import { EditClient } from './components/Edit/EditClient';
import { EditWorker } from './components/Edit/EditWorker';
import { EditService } from './components/Edit/EditService';
import { EditItem } from './components/Edit/EditItem';
import { EditPaperwork } from './components/Edit/EditPaperwork';
import { EditVehicle } from './components/Edit/EditVehicle';
import { EditInvoice } from './components/Edit/EditInvoice';

import { DeleteQuote } from './components/Delete/DeleteQuote';
import { DeleteEvent } from './components/Delete/DeleteEvent';
import { DeleteClient } from './components/Delete/DeleteClient';
import { DeleteWorker } from './components/Delete/DeleteWorker';
import { DeleteService } from './components/Delete/DeleteService';
import { DeleteItem } from './components/Delete/DeleteItem';
import { DeletePaperwork } from './components/Delete/DeletePaperwork';
import { DeleteVehicle } from './components/Delete/DeleteVehicle';
import { DeleteInvoice } from './components/Delete/DeleteInvoice';

import { Gis } from './components/Gis/Gis';
import { QuoteSuccess } from './components/QuoteSuccess/QuoteSuccess';
import { Profile } from './components/Profile/Profile';
import { ModifyRecurringEvent } from './components/Events/ModifyRecurring/ModifyRecurring';


function App() {
  const { isAdmin, isLoading, isAuthenticated, signOut, sessionExpiry } = useAuthContext();

  // Hook for managing the state of the Side-Navigation
  const { open, toggleDrawer } = useSideNav();

  // Per component isLoading
  if (isLoading) {
    return <></>
  }

  if (sessionExpiry < new Date()) {
    signOut();
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ClientProvider>
          <PaperworkProvider>
            <VehicleProvider>
              <WorkerProvider>
                <ItemProvider>
                  <InvoiceProvider>
                    <ServiceProvider>
                      <QuoteProvider>
                        <EventProvider>
                          <Box sx={{
                            display: 'flex',
                            mt: 8,
                          }}>
                            <AppBar position="absolute" open={open}>
                              <Toolbar
                              // sx={{
                              //   pr: '24px', // keep right padding when drawer closed
                              // }}
                              >
                                {isAuthenticated &&
                                  <IconButton
                                    edge="start"
                                    color="inherit"
                                    aria-label="open drawer"
                                    onClick={toggleDrawer}
                                  // sx={{
                                  //   left: '0.5vw',
                                  // }}
                                  >
                                    {open && <MenuIcon />}
                                    {!open && <MenuOpenIcon />}
                                  </IconButton>
                                }
                                <Typography
                                  component="h1"
                                  variant="h6"
                                  color="inherit"
                                  noWrap
                                  sx={{ flexGrow: 1 }}
                                >
                                  <header>
                                    <Navigation />
                                  </header>
                                </Typography>
                                <IconButton color="inherit">
                                  <Badge badgeContent={0} color="secondary">
                                    <NotificationsIcon />
                                  </Badge>
                                </IconButton>
                              </Toolbar>
                            </AppBar>
                            {isAuthenticated &&
                              <Drawer variant="permanent" open={open}>
                                <Toolbar
                                  sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'flex-end',
                                    px: [2],
                                  }}
                                >
                                  <IconButton onClick={toggleDrawer}>
                                    {open ?
                                      <ChevronLeftIcon /> :
                                      <ArrowForwardIosIcon />}
                                  </IconButton>
                                </Toolbar>
                                <Divider />
                                <ListItems isAdmin={isAdmin} toggleDrawer={toggleDrawer} />
                              </Drawer>
                            }

                            <Box
                              component="main"
                              sx={{
                                backgroundColor: (theme) =>
                                  theme.palette.mode === 'light'
                                    ? theme.palette.grey[100]
                                    : theme.palette.grey[900],
                                flexGrow: 1,
                                height: '100vh',
                                width: '100vw',
                                overflow: 'auto',
                              }}
                            >
                              <Box sx={{
                                height: "100%",
                                width: '100%',
                              }}>
                                {/* Spacing between the Drawer and the end of screen to the right */}
                                <Box sx={{ flexGrow: 1, mt: 0, mx: 0 }}>

                                  <Routes>
                                    <Route path='/' element={<Home />} />
                                    <Route path='/profile' element={<Profile />} />
                                    <Route path='/request-cleaning' element={<Quote />} />
                                    <Route path='/request-success' element={<QuoteSuccess />} />
                                    <Route path='/events' element={<EventTable />} />
                                    <Route path='/quotes' element={<QuoteTable />} />
                                    <Route path='/clients' element={<ClientTable />} />
                                    <Route path='/services' element={<ServiceTable />} />
                                    <Route path='/workers' element={<WorkerTable />} />
                                    <Route path='/items' element={<ItemTable />} />
                                    <Route path='/vehicles' element={<VehicleTable />} />
                                    <Route path='/paperworks' element={<PaperworkTable />} />
                                    <Route path='/invoices' element={<InvoiceTable />} />
                                    <Route path='/calendar' element={<Schedule />} />
                                    <Route path='/dashboard' element={<Dashboard />} />
                                    {/* <Route path='/events/:eventId' element={<h1>Event Details</h1>}></Route> */}

                                    <Route element={<RouteGuard />} >
                                      <Route path='/add-service' element={<Service />} />
                                      <Route path='/add-item' element={<Item />} />
                                      <Route path='/add-paperwork' element={<Paperwork />} />
                                      <Route path='/add-vehicle' element={<Vehicle />} />
                                      <Route path='/add-client' element={<Client />} />
                                      <Route path='/add-worker' element={<Worker />} />
                                      <Route path='/add-event' element={<Event />} />
                                      <Route path='/add-invoice' element={<Invoice />} />
                                      <Route path='/events/:eventId/edit' element={
                                        <EditEvent />
                                      } />
                                      <Route path='/events/:eventId/modify' element={
                                        <ModifyRecurringEvent />
                                      } />
                                      <Route path='/events/:eventId/delete' element={
                                        <DeleteEvent />
                                      } />
                                      {/* <Route path='/events/:eventId/delete' element={
                                <DeleteEvent />
                              } /> */}
                                      <Route path='/quotes/:quoteId/edit' element={
                                        <EditQuote />
                                      } />
                                      <Route path='/quotes/:quoteId/delete' element={
                                        <DeleteQuote />
                                      } />
                                      <Route path='/quotes/:quoteId/approve' element={
                                        <ApproveQuote />
                                      } />

                                      <Route path='/services/:serviceId/edit' element={
                                        <EditService />
                                      } />
                                      <Route path='/services/:serviceId/delete' element={
                                        <DeleteService />
                                      } />

                                      <Route path='/paperworks/:paperworkId/edit' element={
                                        <EditPaperwork />
                                      } />
                                      <Route path='/paperworks/:paperworkId/delete' element={
                                        <DeletePaperwork />
                                      } />

                                      <Route path='/vehicles/:vehicleId/edit' element={
                                        <EditVehicle />
                                      } />
                                      <Route path='/vehicles/:vehicleId/delete' element={
                                        <DeleteVehicle />
                                      } />

                                      <Route path='/invoices/:invoiceId/edit' element={
                                        <EditInvoice />
                                      } />
                                      <Route path='/invoices/:invoiceId/delete' element={
                                        <DeleteInvoice />
                                      } />

                                      <Route path='/items/:itemId/edit' element={
                                        <EditItem />
                                      } />
                                      <Route path='/items/:itemId/delete' element={
                                        <DeleteItem />
                                      } />

                                      <Route path='/clients/:clientId/edit' element={
                                        <EditClient />
                                      } />
                                      <Route path='/clients/:clientId/delete' element={
                                        <DeleteClient />
                                      } />

                                      <Route path='/workers/:workerId/edit' element={
                                        <EditWorker />
                                      } />
                                      <Route path='/workers/:workerId/delete' element={
                                        <DeleteWorker />
                                      } />
                                      {/* <Route path='/events/:eventId/edit' element={
                    <CommuteOwner>
                    <Edit />
                    </CommuteOwner>
                  } /> */}
                                      <Route path='/logout' element={<Logout />} />
                                    </Route>

                                    <Route element={<SessionGuard />} >
                                      <Route path='/login' element={<Login />} />
                                      <Route path='/gis' element={<Gis />} />
                                      <Route path='/register' element={<h1>Register Page</h1>} />
                                    </Route>

                                    <Route path='/*' element={<h1>Not Found Page</h1>} />
                                  </Routes>
                                </Box>
                              </Box>
                            </Box>
                          </Box>

                          <Footer />

                        </EventProvider>
                      </QuoteProvider>
                    </ServiceProvider>
                  </InvoiceProvider>
                </ItemProvider>
              </WorkerProvider>
            </VehicleProvider>
          </PaperworkProvider>
        </ClientProvider>
      </LocalizationProvider>
    </>
  );
}

export default App;
