import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';
import { useService } from '../../hooks/useService';

import EditIcon from '@mui/icons-material/Edit';
import {
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
} from '@mui/material'

import { useItemContext } from '../../contexts/ItemContext';
import { itemServiceFactory } from '../../services/itemService';

export const EditItem = () => {
    const { itemId } = useParams();
    const itemService = useService(itemServiceFactory);
    const { itemAlertMessage, setITemAlertMessage, onEditItemSubmit } = useItemContext();

    const initialValues = {
        item: '',
        description: '',
        price: '',
        manHours: '',
        hourlyRate: '',
        totalCharge: '',
        cleanersRate: '',
        cleanersPay: '',
    };

    const [formErrors, setFormErrors] = useState({
        item: '',
        description: '',
        price: '',
        manHours: '',
        hourlyRate: '',
        totalCharge: '',
        cleanersRate: '',
        cleanersPay: '',
    });

    const { values, changeHandler, onSubmit, changeValues } = useForm(initialValues, onEditItemSubmit);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    useEffect(() => {
        itemService.getOne(itemId)
            .then(result => {
                changeValues(result);
            });
        // eslint-disable-next-line
    }, [itemId]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <EditIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Edit Item Details
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.item}
                        helperText={formErrors.item}
                        value={values.item}
                        margin="normal"
                        required
                        fullWidth
                        id="item"
                        label="Item Name"
                        name="item"
                        autoComplete="item"
                        autoFocus
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.description}
                        helperText={formErrors.description}
                        value={values.description}
                        margin="normal"
                        multiline
                        rows={4}
                        // required
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete="description"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.price}
                        helperText={formErrors.price}
                        value={values.price}
                        margin="normal"
                        required
                        fullWidth
                        id="price"
                        label="Price"
                        name="price"
                        autoComplete="price"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.manHours}
                        helperText={formErrors.manHours}
                        value={values.manHours}
                        margin="normal"
                        required
                        fullWidth
                        id="manHours"
                        label="Man-Hours"
                        name="manHours"
                        autoComplete="manHours"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.hourlyRate}
                        helperText={formErrors.hourlyRate}
                        value={values.hourlyRate}
                        margin="normal"
                        required
                        fullWidth
                        id="hourlyRate"
                        label="Hourly Rate"
                        name="hourlyRate"
                        autoComplete="hourlyRate"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.totalCharge}
                        helperText={formErrors.totalCharge}
                        value={values.totalCharge}
                        margin="normal"
                        required
                        fullWidth
                        id="totalCharge"
                        label="Total Charge"
                        name="totalCharge"
                        autoComplete="totalCharge"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.cleanersRate}
                        helperText={formErrors.cleanersRate}
                        value={values.cleanersRate}
                        margin="normal"
                        required
                        fullWidth
                        id="cleanersRate"
                        label="Cleaners Rate"
                        name="cleanersRate"
                        autoComplete="cleanersRate"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.cleanersPay}
                        helperText={formErrors.cleanersPay}
                        value={values.cleanersPay}
                        margin="normal"
                        required
                        fullWidth
                        id="cleanersPay"
                        label="Cleaners Pay"
                        name="cleanersPay"
                        autoComplete="cleanersPay"
                    />

                    {itemAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setITemAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {itemAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                </Box>
            </Box>
        </Container >
    );
};