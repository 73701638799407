import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

// import DashboardIcon from '@mui/icons-material/Dashboard';
import PersonIcon from '@mui/icons-material/Person';
import HomeIcon from '@mui/icons-material/Home';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PeopleIcon from '@mui/icons-material/People';
import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import ExtensionIcon from '@mui/icons-material/Extension';
import EngineeringIcon from '@mui/icons-material/Engineering';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ReceiptIcon from '@mui/icons-material/Receipt';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { Divider, Link, List } from '@mui/material';
import { useLink } from '../../hooks/useLink';

export const ListItems = ({
  isAdmin,
  toggleDrawer
}) => {
  const navigate = useLink();

  const mainListItems = (
    <>
      {/* <ListItemButton>
      <ListItemIcon>
        <DashboardIcon /> // Template for Pure List Item
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton> */}
      <Link href='/events' onClick={(e) => navigate(e, '/events', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <CleaningServicesIcon />
          </ListItemIcon>
          <ListItemText primary="Cleanings" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
      <Link href='/quotes' onClick={(e) => navigate(e, '/quotes', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <RequestQuoteIcon />
          </ListItemIcon>
          <ListItemText primary="Quotes" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
      <Link href='/clients' onClick={(e) => navigate(e, '/clients', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Customers" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
      <Link href='/services' onClick={(e) => navigate(e, '/services', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <RoomServiceIcon />
          </ListItemIcon>
          <ListItemText primary="Services" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
      <Link href='/items' onClick={(e) => navigate(e, '/items', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <ExtensionIcon />
          </ListItemIcon>
          <ListItemText primary="Service Items" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
      <Link href='/workers' onClick={(e) => navigate(e, '/workers', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <EngineeringIcon />
          </ListItemIcon>
          <ListItemText primary="Service Providers" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
      <Link href='/paperworks' onClick={(e) => navigate(e, '/paperworks', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <InsertDriveFileIcon />
          </ListItemIcon>
          <ListItemText primary="Paperwork" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
      <Link href='/vehicles' onClick={(e) => navigate(e, '/vehicles', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <DirectionsCarIcon />
          </ListItemIcon>
          <ListItemText primary="Vehicles" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
      <Link href='/invoices' onClick={(e) => navigate(e, '/invoices', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <ReceiptIcon />
          </ListItemIcon>
          <ListItemText primary="Invoicing" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
    </>
  );

  const userListItems = (
    <>
      {/* <ListItemButton>
      <ListItemIcon>
        <DashboardIcon /> // Template for Pure List Item
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton> */}
      <Link href='/' onClick={(e) => navigate(e, '/', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary="Home" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
      <Link href='/profile' onClick={(e) => navigate(e, '/profile', toggleDrawer)} sx={{ textDecoration: 'none' }}>
        <ListItemButton>
          <ListItemIcon>
            <PersonIcon />
          </ListItemIcon>
          <ListItemText primary="Profile" sx={{
            color: 'text.primary',
            textDecoration: 'none',
          }} />
        </ListItemButton>
      </Link>
    </>
  );

  const secondaryListItems = (
    <>
      <ListSubheader component="div" inset>
        Saved reports
      </ListSubheader>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="May 2023" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="June 2023" />
      </ListItemButton>
      <ListItemButton>
        <ListItemIcon>
          <AssignmentIcon />
        </ListItemIcon>
        <ListItemText primary="July 2023" />
      </ListItemButton>
    </>
  );

  return (
    <List component="nav">
      {isAdmin ?
        <>
          {mainListItems}
          <Divider sx={{ my: 1 }} />
          {/* {secondaryListItems} */}
        </> :
        <>
          {userListItems}
          <Divider sx={{ my: 1 }} />
          {/* {secondaryListItems} */}
        </>
      }
    </List>
  )
}
