import { useState } from 'react';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { StaticDateTimePicker } from '@mui/x-date-pickers'

import {
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
    Autocomplete,
} from '@mui/material'

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';

import { usePaperworkContext } from '../../contexts/PaperworkContext';

export const Paperwork = () => {
    const [inputValue, setInputValue] = useState('');
    const { paperworkAlertMessage, setPaperworkAlertMessage, onCreatePaperworkSubmit } = usePaperworkContext();

    const initialValues = {
        title: '',
        description: '',
        expiryDate: '',
        type: '',
    };

    const [formErrors, setFormErrors] = useState({
        title: '',
        description: '',
        expiryDate: '',
        type: '',
    });

    const { values, changeHandler, onSubmit } = useForm(initialValues, onCreatePaperworkSubmit);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    console.log(values)

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <FileCopyIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Add Paperwork
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.title}
                        helperText={formErrors.title}
                        value={values.title}
                        margin="normal"
                        required
                        fullWidth
                        id="title"
                        label="Title"
                        name="title"
                        autoComplete="name"
                        autoFocus
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.description}
                        helperText={formErrors.description}
                        value={values.description}
                        margin="normal"
                        multiline
                        rows={4}
                        // required
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete="description"
                    />
                    {/* <FormControl fullWidth margin="normal">
                        <InputLabel id="type">Type</InputLabel>
                        <Select
                            labelId="type-label"
                            id="type"
                            value={values.undefined}
                            label="type"
                            onChange={changeHandler}
                        >
                            <MenuItem value={"DBS Check"}>DBS Check</MenuItem>
                            <MenuItem value={'Insurance'}>Insurance</MenuItem>
                        </Select>
                    </FormControl> */}

                    <Autocomplete
                        // onChange={changeHandler}
                        value={values.type}
                        onChange={(event, newValue) => {
                            values.type = newValue
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        fullWidth
                        required
                        disablePortal
                        id="type"
                        label="Type"
                        name="type"
                        autoComplete="type"
                        options={["DBS Check", "Insurance"]}
                        renderInput={(params) => <TextField
                            margin='normal'
                            {...params}
                            label="Type *"
                        />}
                    />

                    <StaticDateTimePicker
                        // maxDate={new Date('future')}
                        // minDate={new Date('nearFuture')}
                        onChange={(newValue, changeHandler) => {
                            values.expiryDate = newValue?.$d
                        }}
                        value={values.expiryDate}
                        minutesStep={5}
                        slots={{
                            actionBar: 'div',
                        }}
                        disablePast={true}
                    />

                    {paperworkAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setPaperworkAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {paperworkAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                </Box>
            </Box>
        </Container >
    );
};