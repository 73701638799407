export const ServiceDialog = ({ service, description, manHours, hourlyRate, totalCharge, cleanersRate, cleanersPay, invoicing, items, updatedAt, createdAt }) => {
    return (
        <>
            <p><strong>{service}</strong></p>
            <p>{description}</p>
            <hr />
            <p>Man-Hours: <strong>{manHours}</strong></p>
            <p>Hourly Rate: <strong>£{hourlyRate}</strong></p>
            <p>Total Charge: <strong>£{totalCharge}</strong></p>
            <hr />
            <p>Cleaners Rate: <strong>£{cleanersRate}</strong></p>
            <p>Cleaners Pay: <strong>£{cleanersPay}</strong></p>
            <p>Default Invoicing: <strong>{invoicing}</strong></p>
            <hr />
            {items?.length > 0 && items?.map(item =>
                <>
                    <p><strong>{item.item}</strong></p>
                    <p>{item.description}</p>
                    <p>Man-Hours: <strong>{item.manHours}</strong></p>
                    <p>Extra Charge: <strong>£{item.totalCharge}</strong></p>
                    <hr />
                </>
            )}
            <p>Last Modified: <strong>{(new Date(updatedAt)).toDateString()}</strong></p>
            <p>Created At: <strong>{(new Date(createdAt)).toDateString()}</strong></p>
        </>
    );
};