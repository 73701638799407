import { Navigate } from 'react-router-dom';

import { useAuthContext } from '../../contexts/AuthContext';

export const Logout = () => {
    const { signOut } = useAuthContext();
    
    signOut();

    return (
        <Navigate to="/" />
    );
};