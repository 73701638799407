import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#556cd6',
    },
    secondary: {
      main: '#19857b',
    },
    white: {
      main: '#fff',
    },
    icons: {
      main: '#556cd6',
      github: '#24292f',
      youtube: '#ff0000',
      whatsapp: '#26d266',
      linkedin: '#0a66c2',
      facebook: '#1877f2',
      telegram: '#33aae6',
      twitter: '#27a3e0',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;