export const WorkerDialog = ({ firstName, lastName, email, phoneNumber, paperwork, updatedAt, createdAt }) => {
    return (
        <>
        <p><strong>{firstName} {lastName}</strong></p>
        <hr />
        <p>Phone: <strong>{phoneNumber}</strong></p>
        <p>Email: <strong>{email}</strong></p>
        <hr />
        {paperwork?.length > 0 && paperwork?.map(paperworkItem =>
            <>
                <p><strong>{paperworkItem.title}</strong></p>
                <p>Expiry Date: <strong>{(new Date(paperworkItem.expiryDate)).toDateString()}</strong></p>
                <hr />
            </>
        )}
        <p>Last Modified: <strong>{(new Date(updatedAt)).toDateString()}</strong></p>
        <p>Created At: <strong>{(new Date(createdAt)).toDateString()}</strong></p>
    </>
    );
};