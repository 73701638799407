import Grid from '@mui/material/Grid';
import { useQuoteContext } from '../../contexts/QuoteContext';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useClientContext } from '../../contexts/ClientContext';
import { getClient } from '../../utils/textUtils';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import CheckIcon from '@mui/icons-material/Check';
import { Avatar } from '@mui/material';

export const QuoteSuccess = () => {
  const { newQuote } = useQuoteContext();
  const { clients } = useClientContext();

  return (
    <Container maxWidth="100vw" sx={{ mt: 4, mb: 4, alignItems: 'center' }}>
      <Grid container spacing={3}>
        {/* Chart */}

        <Grid item xs={12} md={4} lg={3}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              // height: 240,
            }}
          >
            <Avatar sx={{ my: 3, bgcolor: 'secondary.main' }}>
              <AddLocationAltIcon />
            </Avatar>
            <Typography variant='body1'>
              {`Dear ${getClient(newQuote?.client, clients)[0]?.firstName},`}
            </Typography>

            <Typography variant='body1'>
              <p></p>
              {`We are pleased to inform you that your booking for cleaning has been successfully made for ${new Date(newQuote?.start?.dateTime).toLocaleDateString("en-GB")}.
              Our team is excited to provide you with our professional cleaning services to meet your needs.
              If you have any further requirements or questions, please do not hesitate to contact us.
              Thank you for choosing our services. We look forward to serving you!`}
            </Typography>

            <Typography variant='body1'>
              <p></p>
              {`Best regards,`}
            </Typography>

            <Typography variant='body1'>
              {`-Titan Cleaners Team`}
            </Typography>
          </Paper>
        </Grid>
        {/* Recent Deposits */}
        <Grid item xs={12} md={8} lg={9}>
          <Paper
            sx={{
              p: 2,
              display: 'flex',
              flexDirection: 'column',
              // height: 240,
            }}
          >
            <Avatar sx={{ my: 1, bgcolor: 'secondary.main' }}>
              <CheckIcon />
            </Avatar>
            <Typography variant='body1'>
              {'Success'}
            </Typography>
            <Typography variant='body1'>
              <p>
                Your booking request has been received successfuly and will be reviewed shortly!
                <CheckBoxIcon color='secondary' fontSize='lg' />
              </p>
              <p>{newQuote?.summary}</p>
              <p>
                {new Date(newQuote?.start?.dateTime).toLocaleDateString("en-GB")}
                {' - '}
                {new Date(newQuote?.start?.dateTime).toLocaleTimeString("en-GB", { hour: 'numeric', minute: "numeric" })}
              </p>
              <p>{newQuote?.description}</p>
            </Typography>


          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};