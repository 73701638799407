export const dialogs = {
    cleanings: 'Cleaning Details',
    quotes: 'Quote Details',
    client: 'Client Details',
    worker: 'Worker Details',
    serviceData: 'Service Details',
    serviceItems: 'Item Details',
    paper: 'Paperwork Details',
    vehicle: 'Vehicle Details',
    invoice: 'Invoice Details',
}

export const cleaningsTitles = {
    today: 'Daily Agenda',
    upcoming: 'Upcoming Cleanings',
    month: 'Cleanings',
}

export const months = {
    1: 'January',
    2: 'February',
    3: 'March',
    4: 'April',
    5: 'May',
    6: 'June',
    7: 'July',
    8: 'August',
    9: 'September',
    10: 'October',
    11: 'November',
    12: 'December',
}

export const monthPeriods = {
    0: 31,
    1: 28,
    2: 31,
    3: 30,
    4: 31,
    5: 30,
    6: 31,
    7: 31,
    8: 30,
    9: 31,
    10: 30,
    11: 31,
}

export const intervals = [
    {
        value: 1,
        label: 'Weekly'
    },
    {
        value: 2,
        label: 'Bi-Weekly'
    },
    {
        value: 3,
        label: 'Every 3 Weeks'
    },
    {
        value: 4,
        label: 'Every 4 Weeks'
    },
    {
        value: 6,
        label: 'Every 6 Weeks'
    },
    {
        value: 8,
        label: 'Every 8 Weeks'
    },
    {
        value: 9,
        label: 'Every 9 Weeks'
    },
    {
        value: 12,
        label: 'Every 12 Weeks'
    },
    {
        value: 26,
        label: 'Twice a Year'
    },
    {
        value: 52,
        label: 'Yearly'
    },
]

export const days = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
}

export const dailyOptions = [
    {
        key: "sunday",
        label: "S"
    },
    {
        key: "monday",
        label: "M"
    },
    {
        key: "tuesday",
        label: "T"
    },
    {
        key: "wednesday",
        label: "W"
    },
    {
        key: "thursday",
        label: "T"
    },
    {
        key: "friday",
        label: "F"
    },
    {
        key: "saturday",
        label: "S"
    }
];

export const admins = ['titancleanersuk@gmail.com', 'ilkothetiger@gmail.com', 'nkalamski@gmail.com', 'presiyan.tsvetanov@gmail.com']
