import { useClientContext } from "../contexts/ClientContext";
import { useItemContext } from "../contexts/ItemContext";
import { useWorkerContext } from "../contexts/WorkerContext";
import { useInvoiceContext } from "../contexts/InvoiceContext";

import { formatDate, getDuration } from "../utils/dateUtils";
import { getClientDetails, getEventDescription, getInvoicingDetails, getServiceItems, getTeamDetails } from "../utils/textUtils";

import { days } from "../utils/mappers";
import { getTeam } from "../utils/eventUtils";

export const useShapeEvents = () => {
    const { clients } = useClientContext();
    const { workers } = useWorkerContext();
    const { invoices } = useInvoiceContext();
    const { items } = useItemContext();

    return async function (events) {
        events?.map(function (event) {
            event.attachments = event.attachments || []
            event.starts = event.start.dateTime ? formatDate(event.start.dateTime) : formatDate(event.start.date)
            event.day = event.start.dateTime ? days[new Date(event.start.dateTime).getDay()] : days[new Date(event.start.date).getDay()]
            event.time = event.start.dateTime ? `${new Date(event.start.dateTime).getHours()} ${String(new Date(event.start.dateTime).getMinutes()).padStart(2, '0')}` : ''
            event.client = getClientDetails(event.extendedProperties?.private?.client, clients)
            event.duration = getDuration(event.start.dateTime, event.end.dateTime)
            event.cleaners = event.extendedProperties?.private?.team?.split(' ')?.length || ''
            event.manHours = event.start.dateTime ? (event.duration * (event.cleaners || 1)).toFixed(2) : ''
            event.hourlyRate = Number(event.extendedProperties?.private?.hourlyRate)?.toFixed(2)
            // event.totalCharge = manHours * hourlyRate
            event.totalCharge = Number(event.extendedProperties?.private?.totalCharge)?.toFixed(2)
            event.cleanersRate = Number(event.extendedProperties?.private?.cleanersRate)?.toFixed(2)
            // event.cleanersPay = (duration * cleanersRate).toFixed(2)
            event.cleanersPay = Number(event.extendedProperties?.private?.cleanersPay)?.toFixed(2)
            event.team = getTeam(event.extendedProperties?.private?.team, workers)
            event.invoicing = getInvoicingDetails(event.extendedProperties?.private?.invoicingPlatform, invoices)
            event.serviceItems = getServiceItems(event.extendedProperties?.private?.items?.split(' '), items)
            event.description = getEventDescription(event.description, event.extendedProperties?.shared?.bedConfig)
            event.teamMembers = getTeamDetails(event.extendedProperties?.private?.team, workers)
            return event
        })
    }
};