export const shortened = (string, length) => {
  if (String(string).length <= length) {
    return string ? `${string?.slice(0, length)}` : ''
  }
  return string ? `${string?.slice(0, length)}..` : ''
}

export const getClient = (id, clients) => {
  return clients?.filter(client => client._id === id)
}

export const getClientDetails = (id, clients) => {
  const clientData = clients.filter(client => client._id === id)
  let client = {}
  if (clientData.length > 0) {
    client = clientData[0]
  } else {
    return 'No Client Details'
  }
  return `${client.firstName} ${client.lastName}, ${client.phoneNumber}, ${client.email}`
}

export const getTeamDetails = (teamList, workers) => {
  return workers?.filter(worker => teamList?.includes(worker._id))
}

export const getServiceDetails = (serviceId, services) => services?.filter(service => serviceId === service._id)

export const getInvoicingDetails = (id, invoices) => {
  const invoiceData = invoices.filter(invoice => invoice._id === id)
  let invoice = {}
  if (invoiceData.length > 0) {
    invoice = invoiceData[0]
  } else {
    return 'No Invoicing Details'
  }
  return invoice.platform
}

export const getInvoicingPlatform = (id, invoices) => {
  return invoices?.filter(invoice => invoice._id === id);
}

export const getServiceItems = (idList, items) => items?.filter(item => idList?.includes(item._id))

export function capitalizeFirstLetter(str) {

  // converting first letter to uppercase
  return str?.replace(/^./, str[0].toUpperCase());
}

export function getFirstNameFromEmail(str) {
  return capitalizeFirstLetter(str?.split('@')[0]?.split('.')[1]) || "Admin"
}

export function getCleaningsTitle(today, upcoming, month) {
  if (today) {
    return 'today'
  }

  if (upcoming) {
    return 'upcoming'
  }

  if (month) {
    return 'month'
  }
}

// Used for providing Folder Name when Address is not present in the Event during Image Uploading
export const getClientName = (client) => {

  // Gets the Client name from the Client Details column data of the Event Tables
  return client.split(',')[0]
}

export const getEventDescription = (description, bedConfig) => {
  if (bedConfig) {
    return (bedConfig + `\n` + description);
  }

  return description
}