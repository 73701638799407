export const currentDateTime = () => {
    const date = new Date();
    return `
        ${date.getFullYear()}-
        ${String(date.getMonth() + 1).padStart(2, '0')}-
        ${String(date.getDate()).padStart(2, '0')}T
        ${String(date.getHours()).padStart(2, '0')}:
        ${String(date.getMinutes()).padStart(2, '0')}`.replace(/\s+/g, '').trim();

};

/**
 * Formats a date object to a custom ISO string representation.
 * @param {Date} date - The date object to format.
 * @returns {string} The custom ISO string representation of the date.
 * @example // 20230928T111500Z
 */
export function formatDateToCustomISO(date) {
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0');
    const day = String(date.getUTCDate()).padStart(2, '0');
    const hours = String(date.getUTCHours()).padStart(2, '0');
    const minutes = String(date.getUTCMinutes()).padStart(2, '0');
    const seconds = String(date.getUTCSeconds()).padStart(2, '0');

    return `${year}${month}${day}T${hours}${minutes}${seconds}Z`;
}

export const formatDate = (input) => {
    const date = new Date(input);

    return date.toLocaleString('en-GB', { month: 'short', day: 'numeric' });
}

export const getDay = (input) => {
    const date = new Date(input);

    return date.toLocaleString('en-GB', {day: 'alphabetic' });
}

export const addHours = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60 * 60000);
}

export const addDays = (date, days) => {
    return new Date(date.getTime() + days * 24 * 60 * 60000);
}

export const changeMonth = (date, quantity) => {
    return new Date(date.getFullYear(), date.getMonth() + quantity, 2)
}

export const getDuration = (startDate, endDate) => {
    return (new Date(endDate) - new Date(startDate)) / 3600000
}

export const getDateTimeZone = (date) => {
    return {
        dateTime: date.toISOString(),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
}

