import { useAuthContext } from "../../contexts/AuthContext";
import { usePaperworkContext } from "../../contexts/PaperworkContext";

import { useLink } from "../../hooks/useLink";

import { usePicker } from "../../hooks/usePicker";

import { Typography, Button, IconButton, Link } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from '@mui/material/LinearProgress';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

import { Details } from "../Details/Details";


export const PaperworkTable = () => {
    const { isAdmin } = useAuthContext();
    const { paperworks } = usePaperworkContext();

    const navigate = useLink();

    // Custom Hook for adding Images
    const onAddIconClick = usePicker('paperworks');

    const columns = [
        {
            field: 'actions', headerName: 'Actions', width: 130,
            renderCell: (params) =>
                <>
                    <Link title='Delete' href={`/paperworks/${params.id}/delete`} onClick={(e) => navigate(e, `/paperworks/${params.id}/delete`)} sx={{ color: "text.secondary" }}><DeleteIcon /></Link>
                    <Link title='Edit' href={`/paperworks/${params.id}/edit`} onClick={(e) => navigate(e, `/paperworks/${params.id}/edit`)} sx={{ color: "text.secondary" }}><EditIcon /></Link>
                    <IconButton onClick={() => onAddIconClick(params?.row)}>
                        <AddIcon />
                    </IconButton>
                    <IconButton aria-label="comment">
                        <Details key={params.row.id} {...params.row} paper />
                    </IconButton>
                </>
        },
        { field: 'title', headerName: 'Title', width: 150 },
        { field: 'description', headerName: 'Description', width: 250 },
        { field: 'expiryDate', headerName: 'Expiry Date', width: 250 },
        { field: 'createdAt', headerName: 'Created At', width: 175 },
    ];

    return (
        <>
            <Typography
                variant="h5"
                component='h5'
                sx={{
                    textAlign: 'center', mt: 3, mb: 3,
                }}
            >
                Paperwork
            </Typography>
            {isAdmin &&
                <Button size="small" onClick={(e) => navigate(e, '/add-paperwork')}>
                    Add Paperwork
                </Button>
            }
            <DataGrid
                columns={columns}
                rows={paperworks}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress,
                }}
                slotProps={{
                    columnMenu: { background: 'red', counter: paperworks.length },
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </>
    );
};