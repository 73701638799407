import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { vehicleServiceFactory } from '../services/vehicleService';

export const VehicleContext = createContext();

export const VehicleProvider = ({
    children,
}) => {
    const navigate = useNavigate();
    const vehicleService = vehicleServiceFactory();
    const [vehicles, setVehicles] = useState([]);
    const [vehicleAlertMessage, setVehicleAlertMessage] = useState('');
    const getAllVehicles = async () => {
        const AllVehicles = await vehicleService.getAll();

        AllVehicles?.map(function (vehicle) {
            vehicle.id = vehicle._id
            vehicle.label = `${vehicle.brand} ${vehicle.make} - ${vehicle.plates}`
            vehicle.createdAt = new Date(vehicle.createdAt).toLocaleString()
            return vehicle
        })

        setVehicles(AllVehicles);
    };

    useEffect(() => {
        getAllVehicles()
        // eslint-disable-next-line
    }, []);

    const onCreateVehicleSubmit = async (data) => {
        try {
            const createdVehicle = await vehicleService.create(data);
            createdVehicle.id = createdVehicle._id
            createdVehicle.label = createdVehicle.title
            createdVehicle.createdAt = new Date(createdVehicle.createdAt).toLocaleString()

            setVehicles([...vehicles, createdVehicle])

            navigate('/vehicles');
        } catch (error) {
            setVehicleAlertMessage(error.message)
        }
    };

    const onEditVehicleSubmit = async (values) => {
        try {
            const result = await vehicleService.edit(values._id, values);

            setVehicles(state => state.map(x => x._id === values._id ? { ...result?.vehicle, id: result?.vehicle?._id } : x));

            navigate(`/vehicles/`);
        } catch (error) {
            setVehicleAlertMessage(error.message);
        }
    };

    const deleteVehicle = async (vehicleObject) => {
        await vehicleService.delete(vehicleObject._id)

        setVehicles(state => state.filter(vehicle => vehicle._id !== vehicleObject._id));

        navigate(`/vehicles/`);
    };

    const context = {
        vehicles,
        vehicleAlertMessage,
        setVehicleAlertMessage,
        onCreateVehicleSubmit,
        onEditVehicleSubmit,
        deleteVehicle,
    }

    return (
        <VehicleContext.Provider value={context}>
            {children}
        </VehicleContext.Provider>
    );
};

export const useVehicleContext = () => {
    const context = useContext(VehicleContext);

    return context;
}
