import React, { useEffect } from "react";

import { useAuthContext } from "../../contexts/AuthContext";
import { useEventContext } from '../../contexts/EventContext';

import { useLink } from "../../hooks/useLink";

import { usePicker } from "../../hooks/usePicker";
import { useShapeEvents } from "../../hooks/useShapeEvents";
import { usePeriods } from "../../hooks/usePeriods";

import { formatDate } from "../../utils/dateUtils";

import { Typography, Stack, Button, IconButton, Link } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from '@mui/material/LinearProgress';
import DeleteIcon from '@mui/icons-material/Delete';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';

import { Details } from "../Details/Details";

export const EventTable = () => {

  const { isAdmin, session } = useAuthContext();
  const { events, setEvents, getEvents, cancelEvent, modifyAllFollowing } = useEventContext();

  const navigate = useLink();



  // Custom Hook for adding Images
  const onAddIconClick = usePicker('images');

  // Custom Hook for shaping the Events Objects Array
  const shapeEvents = useShapeEvents();

  // Custom Hook for managing the Periods
  const { period, date, monthName, getNextMonth, getLastMonth, getThisMonth, changePeriod } = usePeriods()

  useEffect(() => {
    setEvents([])
    changePeriod()
    // eslint-disable-next-line
  }, [date]);

  useEffect(() => {
    if (session) {
      getEvents(period);
    }
    // eslint-disable-next-line
  }, [session, period]);

  shapeEvents(events)

  let columns = [
    {
      field: 'actions', headerName: 'Actions', width: 140,
      renderCell: (params) =>
        <>
          {params.row.recurringEventId ?
            <EventBusyIcon onClick={() => cancelEvent(params.row)} /> :
            <Link title='Delete' href={`/events/${params.id}/delete`} onClick={(e) => navigate(e, `/events/${params.id}/delete`)} sx={{ color: "text.secondary" }}><DeleteIcon /></Link>
          }
          {params.row.recurringEventId ?
            // <EditCalendarIcon onClick={() => modifyAllFollowing(params.row)} /> :
            // <IconButton onClick={() => modifyAllFollowing(params.row)}>
            //   <EditCalendarIcon />
            // </IconButton> :
            <Link title='Modify' href={`/events/${params.id}/modify`} onClick={(e) => navigate(e, `/events/${params.id}/modify`)} sx={{ color: "text.secondary" }}><EditIcon /></Link> :
            <Link title='Edit' href={`/events/${params.id}/edit`} onClick={(e) => navigate(e, `/events/${params.id}/edit`)} sx={{ color: "text.secondary" }}><EditIcon /></Link>
          }
          <IconButton onClick={() => onAddIconClick(params?.row)}>
            <AddIcon />
          </IconButton>
          <IconButton aria-label="comment">
            <Details key={params.row.id} {...params?.row?.extendedProperties?.private} {...params.row} cleanings />
          </IconButton>
        </>
    },
    { field: 'summary', headerName: 'Summary', width: 150 },
    { field: 'starts', headerName: 'Date', width: 100 },
    { field: 'day', headerName: 'Day', width: 100 },
    { field: 'time', headerName: 'Time', width: 100 },
    { field: 'location', headerName: 'Address', width: 250 },
    { field: 'client', headerName: 'Client', width: 210 },
    // { field: 'serviceItems', headerName: 'Items', width: 150 },
    {
      field: 'description', headerName: 'Job Details', width: 300,
      // valueGetter: (params) => {
      //   if (!params.value) {
      //     return 'No';
      //   }
      //   // Convert the decimal value to a percentage
      //   return 'Yes';
      // },
    },
    { field: 'manHours', headerName: 'Man-Hours', width: 150 },
    { field: 'hourlyRate', headerName: 'Hourly Rate', width: 150 },
    { field: 'totalCharge', headerName: 'Total Charge', width: 150 },
    { field: 'cleaners', headerName: 'Cleaners', width: 150 },
    { field: 'cleanersRate', headerName: 'Cleaners Rate', width: 150 },
    { field: 'cleanersPay', headerName: 'Cleaners Pay', width: 150 },
    { field: 'team', headerName: 'Team', width: 200 },
    { field: 'invoicing', headerName: 'Invoicing', width: 150 },
  ];


  return (
    <>
      <Typography
        variant="h5"
        component='h5'
        sx={{
          textAlign: 'center', mt: 3, mb: 3,
        }}
      >
        ( {formatDate(period?.timeMin)} - {formatDate(period?.timeMax)} ) Scheduled Cleanings
      </Typography>
      <Stack direction="row" spacing={1} sx={{ mb: 1 }}>
        {isAdmin &&
          <>
            <Button size="small" onClick={(e) => navigate(e, '/add-event')}>
              Add a Cleaning
            </Button>
          </>
        }

        <Button size="small" onClick={getLastMonth}>
          Last Month
        </Button>

        <Button size="small" onClick={getThisMonth}>
          {monthName}
        </Button>

        <Button size="small" onClick={getNextMonth}>
          Next Month
        </Button>
      </Stack>
      <DataGrid
        getRowHeight={() => 'auto'}
        // getEstimatedRowHeight={() => 200}
        checkboxSelection
        disableRowSelectionOnClick
        rows={events}
        columns={columns}
        slots={{
          toolbar: GridToolbar,
          loadingOverlay: LinearProgress,
        }}
        slotProps={{
          columnMenu: { background: 'red', counter: events?.length },
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
          },
        }} />
    </>
  );
};