import { useState, useEffect } from 'react';

import { useEventContext } from '../../contexts/EventContext';
import { useAuthContext } from '../../contexts/AuthContext';

import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { usePeriods } from '../../hooks/usePeriods';
import { addDays } from '../../utils/dateUtils';
import { usePrice } from '../../hooks/usePrice';
import { useLink } from '../../hooks/useLink';
import { cleaningsTitles } from '../../utils/mappers';
import { getCleaningsTitle } from '../../utils/textUtils';


export default function Cleanings({
    today,
    upcoming,
    month
}) {
    const [cleanings, setCleanings] = useState([])

    const { events, getEvents } = useEventContext();

    // Hooks for Personalization, Period Management and Values Calculations
    const { isAdmin, session } = useAuthContext();
    const { period, monthName } = usePeriods();
    const { findPrice, findPay, earnings, salary, cash } = usePrice();
    const navigate = useLink();

    const totalEarnings = earnings(cleanings);
    const totalSalary = salary(cleanings);

    useEffect(() => {
        if (session) {
            getEvents(period);
        }
        // eslint-disable-next-line
    }, [session, period]);

    useEffect(() => {
        setCleanings(events)

        if (today) {
            setCleanings(events?.filter(event => (addDays(new Date(), 2) > new Date(event?.start?.dateTime) && new Date(event?.start?.dateTime)) > new Date()))
        }

        if (upcoming) {
            setCleanings(events?.filter(event => new Date(event?.start?.dateTime) > new Date()))
        }

        // if (lastMonth) {
        // setCleanings(events?.filter(event => (new Date(date.getFullYear(), date.getMonth() - 1, 1) < new Date(event?.start?.dateTime) && new Date(event?.start?.dateTime) < new Date(date.getFullYear(), date.getMonth(), 1))))
        // }

    }, [events, today, upcoming, month])

    return (
        <>
            {cleanings.length ? <>
                <Title>{month && monthName} {cleaningsTitles[getCleaningsTitle(today, upcoming, month)]}</Title>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Location</TableCell>
                            <TableCell align="right">{isAdmin ? 'Price' : 'Pay'}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {cleanings?.map((event) => (
                            <TableRow key={event?.id}>
                                <TableCell>{new Date(event?.start?.dateTime)?.toLocaleString('en-gb', { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</TableCell>
                                <TableCell>{event.location}</TableCell>
                                <TableCell align="right">£{isAdmin ? findPrice(event) : findPay(event)}</TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell rowSpan={3} />
                            <TableCell colSpan={1}>{isAdmin ? 'Total' : 'Salary'}</TableCell>
                            <TableCell align="right">£{isAdmin ? totalEarnings : totalSalary}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={1}>{isAdmin ? 'Wages' : 'Cash Taken'}</TableCell>
                            <TableCell align="right">£{isAdmin ? totalSalary : cash}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={1}>{isAdmin ? 'Profit' : 'To Be Paid'}</TableCell>
                            <TableCell align="right">£{isAdmin ? Number(totalEarnings - totalSalary)?.toFixed(2) : Number(totalSalary - cash)?.toFixed(2)}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                <Link color="primary" href="/events" onClick={navigate} sx={{ mt: 3 }}>
                    Open cleanings table
                </Link>
            </> :
                <>
                    <Title>No {month && monthName} {cleaningsTitles[getCleaningsTitle(today, upcoming, month)]}</Title>
                </>
            }
        </>
    );
}