import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import theme from './theme';
import { ThemeProvider } from '@mui/material/styles';

import { BrowserRouter } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import { AuthProvider } from './contexts/AuthContext';

const supabaseUrl = process.env.REACT_APP_SUPABASE_PROJECT_URL
const supabaseApi = process.env.REACT_APP_SUPABASE_PROJECT_API

const supabase = createClient(
  supabaseUrl,
  supabaseApi
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <SessionContextProvider supabaseClient={supabase}>
            <AuthProvider>
              <App />
            </AuthProvider>
          </SessionContextProvider>
        </ThemeProvider>
      </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
