import { useState } from 'react';

import { dailyOptions, intervals } from '../../../utils/mappers';
import {
    Autocomplete,
    ToggleButton,
    ToggleButtonGroup,
    TextField,
} from '@mui/material'

export const Recurrence = ({
    values,
    changeValues,
}) => {
    const [selectedInterval, setSelectedInterval] = useState({});

    return (
        <>
            <ToggleButtonGroup
                size="small"
                arial-label="Days of the week"
                value={values.days}
                onChange={(event, newValue) => {
                    changeValues({ ...values, days: newValue })
                }}
                sx={{
                    margin: 0,
                    padding: 1,
                }}
            // onChange={(event, value) => setDays(value)}
            >
                {
                    dailyOptions.map((day, index) => (
                        <ToggleButton
                            key={day.key}
                            value={index}
                            aria-label={day.key}
                            sx={
                                {
                                    color: "#692B7C",
                                    "&$selected": {
                                        color: "white",
                                        background: "#692B7C"
                                    },
                                    "&:hover": {
                                        borderColor: "#BA9BC3",
                                        background: "#BA9BC3"
                                    },
                                    "&:hover$selected": {
                                        borderColor: "#BA9BC3",
                                        background: "#BA9BC3"
                                    },
                                    minWidth: 32,
                                    maxWidth: 32,
                                    height: 32,
                                    textTransform: "unset",
                                    fontSize: "0.75rem"
                                }
                            }
                        >
                            {day.label}
                        </ToggleButton>
                    ))
                }
            </ToggleButtonGroup >

            <Autocomplete
                // onChange={changeHandler}
                value={values.interval}
                onChange={(event, newValue) => {
                    values.interval = newValue
                }}
                inputValue={selectedInterval}
                onInputChange={(event, newInputValue) => {
                    setSelectedInterval(newInputValue);
                }}
                fullWidth
                required
                disablePortal
                id="interval"
                label="Recurrence"
                name="interval"
                options={intervals}
                renderInput={(params) => <TextField
                    margin='normal'
                    {...params}
                    label="Recurrence *"
                />}
            />
        </>
    );
};