import { requestFactory } from "./requester"

const baseUrl = process.env.REACT_APP_ENV_HERE === "production"
? 'https://titan-cleaners-be.onrender.com/api/paperworks'
: 'http://localhost:3005/api/paperworks'

export const paperworkServiceFactory = (token) => {
    const request = requestFactory();

    const getAll = async () => {
        const result = await request.get(`${baseUrl}`);
    
        return result;
    };

    const getOne = async (paperworkId) => {
        const result = await request.get(`${baseUrl}/${paperworkId}`);

        return result.paperwork;
    };

    const create = async (paperworkData) => {
        const result = await request.post(baseUrl, paperworkData);

        return result.paperwork;
    };

    const edit = (paperworkId, data) => request.put(`${baseUrl}/${paperworkId}`, data);

    const deletePaperwork = (paperworkId) => request.delete(`${baseUrl}/${paperworkId}`); 

    return {
        getAll,
        getOne,
        create,
        edit,
        delete: deletePaperwork,
    };
};
