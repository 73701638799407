import { useState, useEffect, useRef } from 'react';

/**
* A custom hook that handles the state and functionality of a dialog component.
*
* @returns {Object} An object containing the state and functions for the dialog component.
* - open: A boolean value representing whether the dialog is open or not.
* - scroll: A string value representing the scroll behavior of the dialog.
* - handleClickOpen: A function that opens the dialog with a specified scroll behavior.
* - handleClose: A function that closes the dialog.
* - descriptionElementRef: A reference to the description element within the dialog.
*
* @example
* // Import the hook
* import { useDialog } from './useDialog';
*
* // Use the hook to create Dialog Box
* const { open, scroll, handleClickOpen, handleClose, descriptionElementRef } = useDialog()
*/


export const useDialog = () => {
    const [open, setOpen] = useState(false);
    const [scroll, setScroll] = useState('paper');

    /**
    * A function that opens the dialog with the specified scroll behavior.
    * @param {string} scrollType - The scroll behavior of the dialog. 
    * */
    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    /**
    A function that closes the dialog. 
    */
    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = useRef(null);
    useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        // eslint-disable-next-line
    }, [open]);

    return {
        open,
        scroll,
        handleClickOpen,
        handleClose,
        descriptionElementRef
    };
}