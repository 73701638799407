import { requestFactory } from "./requester"

const baseUrl = process.env.REACT_APP_ENV_HERE === "production"
? 'https://titan-cleaners-be.onrender.com/api/items'
: 'http://localhost:3005/api/items'

export const itemServiceFactory = (token) => {
    const request = requestFactory();

    const getAll = async () => {
        const result = await request.get(`${baseUrl}`);
    
        return result;
    };

    const getOne = async (itemId) => {
        const result = await request.get(`${baseUrl}/${itemId}`);

        return result.item;
    };

    const create = async (itemData) => {
        const result = await request.post(baseUrl, itemData);

        return result.item;
    };

    const edit = (itemId, data) => request.put(`${baseUrl}/${itemId}`, data);

    const deleteItem = (itemId) => request.delete(`${baseUrl}/${itemId}`); 

    return {
        getAll,
        getOne,
        create,
        edit,
        delete: deleteItem,
    };
};
