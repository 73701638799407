import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { workerServiceFactory } from '../services/workerService';

export const WorkerContext = createContext();

export const WorkerProvider = ({
    children,
}) => {
    const navigate = useNavigate();
    const workerService = workerServiceFactory();
    const [workers, setWorkers] = useState([]);
    const [workerAlertMessage, setWorkerAlertMessage] = useState('');

    const getAllWorkers = async () => {
        const allWorkers = await workerService.getAll();

        allWorkers?.map(function (worker) {
            worker.id = worker._id
            worker.label = worker.firstName
            worker.createdAt = new Date(worker.createdAt).toLocaleString()
            return worker
        })

        setWorkers(allWorkers);
    };

    useEffect(() => {
        getAllWorkers()
        // eslint-disable-next-line
    }, []);

    const onCreateWorkerSubmit = async (data) => {
        try {
            const createdVehicle = await workerService.create(data);

            setWorkers([...workers, createdVehicle])

            navigate('/workers');
        } catch (error) {
            setWorkerAlertMessage(error.message)
        }
    };

    const onEditWorkerSubmit = async (values) => {
        try {
            const result = await workerService.edit(values._id, values);

            setWorkers(state => state.map(x => x._id === values._id ? { ...result?.worker, id: result?.worker?._id } : x));

            navigate(`/workers/`);
        } catch (error) {
            setWorkerAlertMessage(error.message);
        }
    };

    const deleteWorker = async (workerObject) => {
        await workerService.delete(workerObject._id)

        setWorkers(state => state.filter(worker => worker._id !== workerObject._id));

        navigate(`/workers/`);
    };

    const context = {
        workers,
        workerAlertMessage,
        setWorkerAlertMessage,
        onCreateWorkerSubmit,
        onEditWorkerSubmit,
        deleteWorker,
    }

    return (
        <WorkerContext.Provider value={context}>
            {children}
        </WorkerContext.Provider>
    );
};

export const useWorkerContext = () => {
    const context = useContext(WorkerContext);

    return context;
}
