import CloseIcon from '@mui/icons-material/Close';

import { ScrollDialog } from '../Dialog/Dialog';

import {
    Box,
    InputAdornment,
    FormControl,
    FilledInput,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    IconButton,
} from '@mui/material'

export const PriceBuilder = ({
    values,
    changeValues,
}) => {
    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
            <div>
                <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                        {values?.service &&
                            <ListItem
                                key={values.service?._id}
                                divider
                                inset
                                // alignItems='left'
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                                // disableGutters
                                secondaryAction={
                                    <IconButton aria-label="comment">
                                        <ScrollDialog {...values.service} />
                                        <CloseIcon onClick={() => changeValues({ ...values, service: '' })} />
                                    </IconButton>
                                }
                            >
                                <ListItemText primary={`${values.service?.service}`} />
                                <ListItemText secondary={`${(Number(values.service?.manHours) || 0)?.toFixed(2)} Man-Hours`} />
                                <ListItemText secondary={`Cost: £${(Number(values.service?.price) || 0)?.toFixed(2)}`} />
                            </ListItem>
                        }
                        {values?.items?.map((value) => (
                            <ListItem
                                key={value._id}
                                slotProps={value}
                                divider
                                // alignItems='center'
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                }}
                                // disableGutters
                                secondaryAction={
                                    <IconButton aria-label="comment">
                                        <ScrollDialog {...value} />
                                        <CloseIcon onClick={() => changeValues({ ...values, items: values.items?.filter(item => item._id !== value._id) })} />
                                    </IconButton>
                                }
                            >
                                <ListItemText primary={`+ ${value?.item}`} />
                                <ListItemText secondary={`${(Number(value?.manHours) || 0)?.toFixed(2)} Man-Hours`} />
                                <ListItemText secondary={`Cost: £${(Number(value?.price) || 0)?.toFixed(2)}`} />
                            </ListItem>
                        ))}
                    </List>
                    <FilledInput
                        id="filled-adornment-weight"
                        value={(Number(values?.manHours) || 0)?.toFixed(2) || 0}
                        endAdornment={
                            <>
                                <InputLabel htmlFor="filled-adornment-weight">Man-Hours</InputLabel>

                                <InputAdornment position="end">hrs</InputAdornment>
                            </>
                        }
                        aria-describedby="filled-weight-helper-text"
                        inputProps={{
                            'aria-label': 'weight',
                        }}
                    />
                    {/* <FormHelperText id="filled-weight-helper-text">Total Man-Hours</FormHelperText> */}
                </FormControl>
                <FormControl fullWidth sx={{ m: 1 }} variant="filled">
                    <InputLabel htmlFor="filled-adornment-amount">Total Price</InputLabel>
                    <FilledInput
                        id="filled-adornment-amount"
                        value={(Number(values?.totalCharge) || 0)?.toFixed(2) || 0}
                        startAdornment={<InputAdornment position="start">£</InputAdornment>}
                    />
                </FormControl>
            </div>
        </Box>
    );
};