import { useItemContext } from "../contexts/ItemContext";
import { useServiceContext } from "../contexts/ServiceContext";
import { getPay, getPrice } from "../utils/eventUtils";

export const usePrice = () => {
    const { services } = useServiceContext();
    const { items } = useItemContext();

    return {
        findPrice: function (event) { return getPrice(event, services, items); },
        findPay: function (event) { return getPay(event, services, items); },
        earnings: function (cleanings) {
            return cleanings?.map(currentEvent => Number(getPrice(currentEvent, services, items)))?.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
            )?.toFixed(2) || '0.00'
        },
        salary: function (cleanings) {
            return cleanings?.map(currentEvent => Number(getPay(currentEvent, services, items)))?.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0
            )?.toFixed(2) || '0.00'
        },
        cash: 0,
    }

};