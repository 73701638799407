import { useEffect, useState } from 'react';

import { useQuoteContext } from '../../contexts/QuoteContext';
import { useServiceContext } from '../../contexts/ServiceContext';
import { useItemContext } from '../../contexts/ItemContext';

import { StaticDateTimePicker } from '@mui/x-date-pickers'

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';

import {
    Today,
} from '@mui/icons-material';

import {
    Autocomplete,
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
} from '@mui/material'

import { PriceBuilder } from '../PriceBuilder/PriceBuilders';
import { getManHours, getTotalCharge } from '../../utils/reduceUtils';
import { getServiceItems } from '../../utils/textUtils';


export const Quote = () => {
    const { quoteAlertMessage, setQuoteAlertMessage, onCreateQuoteSubmit } = useQuoteContext();
    const { services } = useServiceContext();
    const { items } = useItemContext();
    const [inputValue, setInputValue] = useState('')
    const [availableItems, setAvailableItems] = useState([])
    const [selectedService, setSelectedService] = useState([])

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        service: '',
        items: [],
        address: '',
        description: '',
        date: '',
        // service: {
        //     cleanersPay: 48,
        //     cleanersRate: 12,
        //     createdAt: "2023-06-21T12:57:13.256Z",
        //     description: "Double Cleaning Airbnb",
        //     hourlyRate: 16,
        //     id: "6492f3a93e0f8c216c0ae291",
        //     invoicingPlatform: "AMS QB",
        //     label: "Double Cleaning",
        //     manHours: 4,
        //     price: 64,
        //     service: "Double Cleaning",
        //     totalCharge: 64,
        //     updatedAt: "2023-06-21T12:57:13.256Z",
        //     _id: "6492f3a93e0f8c216c0ae291",
        // },
        // date: dayjs(new Date().toISOString()),
    };

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        service: '',
        items: '',
        address: '',
        description: '',
        date: '',
    });

    const { values, changeHandler, onSubmit, changeValues } = useForm(initialValues, onCreateQuoteSubmit);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    useEffect(() => {
        setSelectedService(values.service)
        changeValues({ ...values, items: [] })
        // eslint-disable-next-line
    }, [values.service])

    useEffect(() => {
        changeValues({ ...values, manHours: selectedService.manHours, totalCharge: selectedService.totalCharge })
        setAvailableItems(getServiceItems(selectedService?.items, items));
        // eslint-disable-next-line
    }, [selectedService])

    useEffect(() => {
        changeValues({
            ...values,
            totalCharge: getTotalCharge(values.items, values.service),
            manHours: getManHours(values.items, values.service)
        })
        // eslint-disable-next-line
    }, [values.items])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <Today />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Request a Cleaning
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.firstName}
                        margin="normal"
                        required
                        error={formErrors.firstName}
                        helperText={formErrors.firstName}
                        fullWidth
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        autoComplete="name"
                        autoFocus
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.lastName}
                        margin="normal"
                        required
                        error={formErrors.lastName}
                        helperText={formErrors.lastName}
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lastName"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.email}
                        margin="normal"
                        required
                        error={formErrors.email}
                        helperText={formErrors.email}
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.phoneNumber}
                        margin="normal"
                        required
                        error={formErrors.phoneNumber}
                        helperText={formErrors.phoneNumber}
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        id="phoneNumber"
                        label="Phone Number"
                        name="phoneNumber"
                        autoComplete="phone"
                    />
                    <Autocomplete
                        value={values.service}
                        onChange={(event, newValue) => {
                            values.service = newValue
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        fullWidth
                        required
                        disablePortal
                        id="service"
                        label="Service"
                        name="service"
                        autoComplete="service"
                        options={services}
                        renderInput={(params) => <TextField
                            margin='normal'
                            {...params}
                            label="Service *"
                        />}
                    />

                    {selectedService && availableItems.length > 0 &&
                        <Autocomplete
                            multiple
                            value={values.items}
                            onChange={(event, newValue) => {
                                changeValues({ ...values, items: newValue })
                            }}
                            options={availableItems}
                            // id="service-items"
                            // getOptionLabel={(option) => option.title}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    margin='normal'
                                    {...params}
                                    label="Service Items"
                                    placeholder="+ Add"
                                />
                            )}
                        />
                    }

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.address}
                        margin="normal"
                        required
                        error={formErrors.address}
                        helperText={formErrors.address}
                        fullWidth
                        id="address"
                        label="Address"
                        name="address"
                        autoComplete="address"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.description}
                        margin="normal"
                        multiline
                        rows={4}
                        // required
                        error={formErrors.description}
                        helperText={formErrors.description}
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete="description"
                    />

                    <StaticDateTimePicker
                        // maxDate={new Date('future')}
                        // minDate={new Date('nearFuture')}
                        onChange={(newValue, changeHandler) => {
                            values.date = newValue?.$d
                        }}
                        value={values.date}
                        minutesStep={5}
                        slots={{
                            actionBar: 'div',
                        }}
                        disablePast={true}
                    />

                    <PriceBuilder values={values} changeValues={changeValues} />

                    {quoteAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setQuoteAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {quoteAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
        </Container >
    );
};