import { useContext } from "react";
import { useSupabaseClient } from "@supabase/auth-helpers-react";

import { AuthContext } from "../../contexts/AuthContext";
import { useForm } from "../../hooks/useForm";

import { FormKeys } from "../../utils/formKeys";

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { CssBaseline } from "@mui/material";
import { capitalizeFirstLetter } from "../../utils/textUtils";
import { useLink } from "../../hooks/useLink";

export const Gis = () => {
    const { onLoginSubmit } = useContext(AuthContext);
    const { supabase } = useSupabaseClient();
    const cliendId = process.env.REACT_APP_CLIENT_ID;

    const navigate = useLink();

    async function handleSignInWithGoogle(response) {
        // const { data, error } = await supabase.auth.signInWithIdToken({
        await supabase.auth.signInWithIdToken({
            token: response.credential,
            nonce: '', // must be the same one as provided in data-nonce (if any)
        })
    }

    window.supabase = supabase
    window.handleSignInWithGoogle = handleSignInWithGoogle

    const { values, changeHandler, onSubmit } = useForm({
        [FormKeys.Username]: '',
        [FormKeys.Password]: '',
        [FormKeys.Remember]: false,
    }, onLoginSubmit);

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign In
                    </Typography>
                    <Box component="form" noValidate onSubmit={onSubmit} sx={{ mt: 1, mb: 2 }}>
                        <TextField
                            value={values[FormKeys.Username]}
                            onChange={changeHandler}
                            required
                            margin="normal"
                            fullWidth
                            id={FormKeys.Username}
                            label={capitalizeFirstLetter((FormKeys.Username))}
                            name={FormKeys.Username}
                            autoComplete={FormKeys.Username}
                        />

                        <TextField
                            value={values[FormKeys.Password]}
                            onChange={changeHandler}
                            required
                            margin="normal"
                            fullWidth
                            type={FormKeys.Password}
                            id={FormKeys.Password}
                            label={capitalizeFirstLetter((FormKeys.Password))}
                            name={FormKeys.Password}
                            autoComplete={FormKeys.Password}
                        />
                        {/* <FormControlLabel
                            control={
                                <Checkbox
                                    value={values[FormKeys.Remember]}
                                    checked={values[FormKeys.Remember] ? true : false}
                                    onChange={() => changeValues(() => ({ ...values, [FormKeys.Remember]: !values[FormKeys.Remember] }))}
                                    name={FormKeys.Remember}
                                    color="secondary"
                                    id={FormKeys.Remember}
                                />
                            }
                            label="Remember me"
                        /> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <CssBaseline />
                        <Grid container sx={{ mt: 3, mb: 2 }}>
                            <Grid item>
                                <div id="g_id_onload"
                                    data-client_id={cliendId}
                                    data-context="signin"
                                    data-ux_mode="popup"
                                    data-callback="handleSignInWithGoogle"
                                    data-nonce=""
                                    data-auto_select="true"
                                    data-itp_support="true">
                                </div>

                                <div class="g_id_signin"
                                    data-type="standard"
                                    data-shape="rectangular"
                                    data-theme="outline"
                                    data-text="signin"
                                    data-size="large"
                                    data-locale="en-GB"
                                    data-logo_alignment="left">
                                </div>
                            </Grid>
                        </Grid>
                        <Grid container sx={{ mt: 3, mb: 2 }}>
                            <Grid item>
                                <Link href="#" onClick={navigate} variant="body2">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item md>
                                <Link href="/Login" onClick={navigate} variant="body2">
                                    {"Already have an account? Sign In"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}