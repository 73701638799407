import React, { useEffect } from "react";

import { useEventContext } from '../../contexts/EventContext';
import { useAuthContext } from "../../contexts/AuthContext";

import { usePicker } from "../../hooks/usePicker";
import { useShapeEvents } from "../../hooks/useShapeEvents";
import { usePeriods } from "../../hooks/usePeriods";

import { Stack, Button, IconButton } from "@mui/material";
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@mui/icons-material/Add';
import { Details } from "../Details/Details";
import { useLink } from "../../hooks/useLink";

export const Home = () => {
    const { session } = useAuthContext();
    const { events, setEvents, getEvents } = useEventContext();

    const navigate = useLink();

    // Custom Hook for adding Images
    const onAddIconClick = usePicker('images');

    // Custom Hook for shaping the Events Objects Array
    const shapeEvents = useShapeEvents();

    // Custom Hook for managing the Periods
    const { period, date, monthName, getNextMonth, getLastMonth, getThisMonth, changePeriod } = usePeriods()

    useEffect(() => {
        setEvents([])
        changePeriod()
        // eslint-disable-next-line
    }, [date]);

    useEffect(() => {
        if (session) {
            getEvents(period);
        } else {
            getEvents(period, true);
        }
        // eslint-disable-next-line
    }, [session, period]);

    shapeEvents(events)


    const columns = [
        {
            field: 'actions', headerName: 'Add', width: 90, sortable: false,
            renderCell: (params) =>
                <>
                    {session && <IconButton onClick={() => onAddIconClick(params?.row)}>
                        <AddIcon />
                    </IconButton>}
                    <IconButton aria-label="comment">
                        <Details key={params.row.id} {...params?.row?.extendedProperties?.private} {...params.row} cleanings />
                    </IconButton>
                </>
        },
        { field: 'summary', headerName: 'Summary', width: 70 },
        { field: 'starts', headerName: 'Date', width: 100 },
        { field: 'day', headerName: 'Day', width: 100 },
        { field: 'time', headerName: 'Time', width: 100 },
        { field: 'location', headerName: 'Address', width: 130 },
        { field: 'description', headerName: 'Job Details', width: 130 },
        {
            field: 'manHours',
            headerName: 'Man-Hours',
            type: 'number',
            width: 90,
        },
        // {
        //     field: 'fullName',
        //     headerName: 'Full name',
        //     description: 'This column has a value getter and is not sortable.',
        //     sortable: true,
        //     width: 160,
        //     valueGetter: (params) =>
        //         `${params.row.firstName || ''} ${params.row.lastName || ''}`,
        // },
    ];

    if (session) {
        columns.push(
            { field: 'client', headerName: 'Client', width: 130 },
            // {
            //     field: 'hourlyRate',
            //     headerName: 'Hourly Rate',
            //     type: 'number',
            //     width: 90,
            // },
            // {
            //     field: 'totalCharge',
            //     headerName: 'Total Charge',
            //     type: 'number',
            //     width: 90,
            // },
            {
                field: 'cleaners',
                headerName: 'Cleaners',
                type: 'number',
                width: 90,
            },
            {
                field: 'cleanersRate',
                headerName: 'Hourly Pay',
                type: 'number',
                width: 90,
            },
            {
                field: 'cleanersPay',
                headerName: 'Cleaners Pay',
                type: 'number',
                width: 90,
            },
            { field: 'team', headerName: 'Team', width: 130 },
            { field: 'invoicing', headerName: 'Invoicing', width: 130 },
        )
    }

    const rows = [
        ...events
    ]

    return (
        <div style={{ height: '100%', width: '100%' }}>
            <Stack direction="row" spacing={1} sx={{ m: 2 }}>
                <Button size="small" onClick={getLastMonth}>
                    Last Month
                </Button>

                <Button size="small" onClick={getThisMonth}>
                    {monthName}
                </Button>

                <Button size="small" onClick={getNextMonth}>
                    Next Month
                </Button>

                {!session && <Button size="small" onClick={(e) => navigate(e, '/request-cleaning')}>
                    Request a Cleaning
                </Button>}
            </Stack>
            <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 15 },
                    },
                }}
                pageSizeOptions={[5, 10, 15, 25, 50, 100, 250]}
                checkboxSelection
            />
        </div>
    );
}

