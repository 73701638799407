import { useState } from 'react';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import {
    Autocomplete,
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
} from '@mui/material'

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';

import { useVehicleContext } from '../../contexts/VehicleContext';
import { usePaperworkContext } from '../../contexts/PaperworkContext';

export const Vehicle = () => {
    const { vehicleAlertMessage, setVehicleAlertMessage, onCreateVehicleSubmit } = useVehicleContext();
    const initialValues = {
        brand: '',
        make: '',
        plates: '',
        paperwork: [],
    };

    const [formErrors, setFormErrors] = useState({
        brand: '',
        make: '',
        plates: '',
        paperwork: '',
    });

    const { paperworks } = usePaperworkContext();

    const { values, changeHandler, onSubmit, changeValues } = useForm(initialValues, onCreateVehicleSubmit);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <DirectionsCarIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Add a Vehicle
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.brand}
                        helperText={formErrors.brand}
                        value={values.brand}
                        margin="normal"
                        required
                        fullWidth
                        id="brand"
                        label="Brand"
                        name="brand"
                        autoComplete="name"
                        autoFocus
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.make}
                        helperText={formErrors.make}
                        value={values.make}
                        margin="normal"
                        required
                        fullWidth
                        id="make"
                        label="Make"
                        name="make"
                        autoComplete="make"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.plates}
                        helperText={formErrors.plates}
                        value={values.plates}
                        margin="normal"
                        required
                        fullWidth
                        id="plates"
                        label="Plates"
                        name="plates"
                        autoComplete="plates"
                    />
                    <Autocomplete
                        multiple
                        value={values.paperwork}
                        onChange={(event, newValue) => {
                            changeValues({ ...values, paperwork: newValue })
                        }}
                        options={paperworks}
                        // id="service-items"
                        // getOptionLabel={(option) => option.title}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                margin='normal'
                                {...params}
                                label="Paperwork"
                                placeholder="+ Add"
                            />
                        )}
                    />
                    {vehicleAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setVehicleAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {vehicleAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                </Box>
            </Box>
        </Container >
    );
};