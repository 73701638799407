import { useAuthContext } from '../../contexts/AuthContext';

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Chart from './Chart';
import Quotes from './Quotes';

import Summary from '../Summary/Summary';
import Cleanings from './Cleanings';


export default function Dashboard() {
  const { isAdmin } = useAuthContext()

  return (
    <>
      <Container maxWidth="100vw" sx={{ mt: 4, mb: 4 }}>
        <Grid container spacing={3}>
          {/* Chart */}
          <Grid item xs={12} md={8} lg={9}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
                height: 240,
              }}
            >
              <Chart />
            </Paper>
          </Grid>
          {/* Recent Deposits */}
          <Grid item xs={12} md={4} lg={3}>
            <Paper
              sx={{
                p: 2,
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <Summary isAdmin={isAdmin} />
            </Paper>
          </Grid>
          {/* Upcoming Cleanings */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Cleanings upcoming />
            </Paper>
          </Grid>
          {/* Recent Quotes */}
          <Grid item xs={12}>
            <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
              <Quotes />
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </>
  );
}