export const EventDialog = ({ summary, location, starts, time, client, serviceName, itemsRelated, description, manHours, hourlyRate, totalCharge, cleanersPay, cleanersRate, team, invoicing, status, updatedAt, createdAt }) => {
    return (
        <>
            <p><strong>{summary}</strong></p>
            <p><strong>{starts}, {time}</strong></p>
            <hr />
            <p>Location: <strong>{location}</strong></p>
            <p>Client: <strong>{client}</strong></p>
            <p>Details: <strong>{description}</strong></p>
            {serviceName.length > 0 && <p>Service: <strong>{serviceName}</strong></p>}
            {itemsRelated.length > 0 && <p>Extras: <strong>{itemsRelated?.map(item =>
                <>
                    {item.item && <p><strong>{item.item}</strong></p>}
                    {item.item && <p>{item.description}</p>}
                    {item.manHours && <p>Man-Hours: <strong>{Number(item.manHours)?.toFixed(2)}</strong></p>}
                    {item.totalCharge && <p>Extra Charge: <strong>£{Number(item.totalCharge)?.toFixed(2)}</strong></p>}
                    <hr />
                </>
            )}</strong></p>}
            {/* <p><strong>Summary</strong></p> */}
            <p>Man-Hours: <strong>{manHours}</strong></p>
            {!isNaN(hourlyRate) && <p>Hourly Rate: <strong>£{hourlyRate}</strong></p>}
            {!isNaN(totalCharge) && <p>Total Charge: <strong>£{totalCharge}</strong></p>}
            <hr />
            {!isNaN(cleanersRate) && <p>Cleaners Rate: <strong>£{cleanersRate}</strong></p>}
            {!isNaN(cleanersPay) && <p>Cleaners Pay: <strong>£{cleanersPay}</strong></p>}
            {team &&
                <>
                    <p>Team: <strong>{team}</strong></p>
                    <hr />
                </>
            }

            {invoicing && <p>Invoicing Platform: <strong>{invoicing}</strong></p>}
            <p>Status: <strong>{status}</strong></p>
            <hr />
            <p>Last Modified: <strong>{(new Date(updatedAt)).toDateString()}</strong></p>
            <p>Created At: <strong>{(new Date(createdAt)).toDateString()}</strong></p>
        </>
    );
};