import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Copyright } from '../Copyright/Copyright';

export const Footer = () => {
  return (
    <footer data-testid="footer">
      <Container
        maxWidth="xl"
        sx={{
          position: 'sticky',
          bottom: '0px',
          p: [1],
          backgroundColor: '#fff',
          minWidth: '100%',
        }}>
        <Typography variant="body1">
          Titan Operations App
        </Typography>
        <Copyright />
      </Container>
    </footer>
  );
}