import { requestFactory } from "./requester"

const baseUrl = process.env.REACT_APP_ENV_HERE === "production"
? 'https://titan-cleaners-be.onrender.com/api/workers'
: 'http://localhost:3005/api/workers'

export const workerServiceFactory = (token) => {
    const request = requestFactory();

    const getAll = async () => {
        const result = await request.get(`${baseUrl}`);
    
        return result.workers;
    };

    const getOne = async (workerId) => {
        const result = await request.get(`${baseUrl}/${workerId}`);

        return result.worker;
    };

    const create = async (workerDate) => {
        const result = await request.post(baseUrl, workerDate);

        return result.worker;
    };

    const edit = (workerId, data) => request.put(`${baseUrl}/${workerId}`, data);

    const deleteWorker = (workerId) => request.delete(`${baseUrl}/${workerId}`); 

    return {
        getAll,
        getOne,
        create,
        edit,
        delete: deleteWorker,
    };
};
