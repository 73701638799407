import { requestFactory } from "./requester"

const baseUrl = process.env.REACT_APP_ENV_HERE === "production"
? 'https://titan-cleaners-be.onrender.com/api/vehicles'
: 'http://localhost:3005/api/vehicles'

export const vehicleServiceFactory = (token) => {
    const request = requestFactory();

    const getAll = async () => {
        const result = await request.get(`${baseUrl}`);
    
        return result.vehicles;
    };

    const getOne = async (vehicleId) => {
        const result = await request.get(`${baseUrl}/${vehicleId}`);

        return result.vehicle;
    };

    const create = async (vehicleDate) => {
        const result = await request.post(baseUrl, vehicleDate);

        return result.vehicle;
    };

    const edit = (vehicleId, data) => request.put(`${baseUrl}/${vehicleId}`, data);

    const deleteVehicle = (vehicleId) => request.delete(`${baseUrl}/${vehicleId}`); 

    return {
        getAll,
        getOne,
        create,
        edit,
        delete: deleteVehicle,
    };
};
