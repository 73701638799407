import { days } from "./mappers";

export const ascending = (array) => {
    return (
        array.sort(function (a, b) {
            return new Date(a.start.dateTime) - new Date(b.start.dateTime);
        })
    )
};

export const descending = (array) => {
    return (
        array.sort(function (a, b) {
            return new Date(b.start.dateTime) - new Date(a.start.dateTime);
        })
    )
};

export const hoursAccumulated = (array) => {
    let hoursAccumulated = 0;
    array.map(event => hoursAccumulated += (Number((new Date(event.end.dateTime) - new Date(event.start.dateTime)) * getCleanersCount(event.extendedProperties?.private?.team)) / 3600000) || 0)
    return hoursAccumulated;
}

export const salaryAccumulated = (array) => {
    let salaryAccumulated = 0;
    array.map(event => salaryAccumulated += (Number(event?.extendedProperties?.private?.cleanersPay) - Number(event?.extendedProperties?.private?.cashReceived)) || 0)
    return salaryAccumulated;
}

export const profitMade = (array) => {
    let profitMade = 0;
    array.map(event => profitMade += Number(event?.extendedProperties?.private?.totalCharge) || 0)
    return profitMade;
}

export const getTeam = (teamIdString, workers) => {
    return workers
        ?.filter(worker => teamIdString
            ?.split(' ').includes(worker._id))
        .map(member => member.firstName).join(' ')
}

export const getCleanersCount = (teamIdString) => {
    return teamIdString?.split(' ').length || 1
}

// export const getTotal = (collection, field) => {
//     return collection?.
//                 map(item => item.totalCharge)?.
//                 reduce(
//                     (accumulator, currentValue) => accumulator + currentValue,
//                     Number(values.service.totalCharge)
//                 ) || ''
// }

export const getPrice = (event, services, items) => {
    return Number(items
        ?.filter(item => event?.extendedProperties?.private?.items?.includes(item._id))
        ?.map(item => item.totalCharge)
        ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            Number(services?.filter(service => service._id === event?.extendedProperties?.private?.service)?.map(service => service.totalCharge))
        ))?.toFixed(2) || '0.00'
}

export const getPay = (event, services, items) => {
    return Number(items
        ?.filter(item => event?.extendedProperties?.private?.items?.includes(item._id))
        ?.map(item => item.cleanersPay)
        ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            Number(services?.filter(service => service._id === event?.extendedProperties?.private?.service)?.map(service => service.cleanersPay))
        ) / getCleanersCount(event?.extendedProperties?.private?.team))?.toFixed(2) || '0.00'
}

export const getRecurringDays = (arr) => {
    return arr.map(day => days[day]?.slice(0,2)?.toUpperCase())
}