import { useAuthContext } from "../../contexts/AuthContext";

import { getFirstNameFromEmail } from "../../utils/textUtils";

import { Typography } from "@mui/material";
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Cleanings from "../Dashboard/Cleanings";
import Summary from "../Summary/Summary";

export const Profile = () => {
    const { isAdmin, session } = useAuthContext();

    return (
        <>
            <Container maxWidth="100vw" sx={{ mt: 4, mb: 4 }}>
                <Typography>Welcome, {getFirstNameFromEmail(session?.user?.email)}!</Typography>
                <Grid container spacing={3}>
                    {/* Daily Agenda */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Cleanings today />
                        </Paper>
                    </Grid>
                    {/* User Summary */}
                    <Grid item xs={12} md={4} lg={3}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Summary isAdmin={isAdmin} />
                        </Paper>
                    </Grid>
                    {/* Upcoming Cleanings */}
                    <Grid item xs={12} md={8} lg={9}>
                        <Paper
                            sx={{
                                p: 2,
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Cleanings upcoming />
                        </Paper>
                    </Grid>
                    {/* Current Month */}
                    <Grid item xs={12}>
                        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                            <Cleanings month />
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    );
};  