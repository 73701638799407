import { useState, useEffect, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { paperworkServiceFactory } from '../services/paperworkService';

export const PaperworkContext = createContext();

export const PaperworkProvider = ({
    children,
}) => {
    const navigate = useNavigate();
    const [paperworks, setPaperworks] = useState([]);
    const paperworkService = paperworkServiceFactory();
    const [paperworkAlertMessage, setPaperworkAlertMessage] = useState('');

    useEffect(() => {
        paperworkService.getAll()
            .then(result => {
                result?.paperworks?.map(function (paperwork) {
                    paperwork.id = paperwork._id
                    paperwork.label = paperwork.title
                    paperwork.expiryDate = new Date(paperwork.expiryDate).toDateString()
                    paperwork.createdAt = new Date(paperwork.createdAt).toLocaleString()
                    return paperwork
                })
                setPaperworks(result.paperworks);
            });
        // eslint-disable-next-line
    }, []);

    const onCreatePaperworkSubmit = async (data) => {
        try {
            const createdPaperwork = await paperworkService.create(data);
            createdPaperwork.id = createdPaperwork._id
            createdPaperwork.label = createdPaperwork.title
            createdPaperwork.expiryDate = new Date(createdPaperwork?.expiryDate).toDateString()
            createdPaperwork.createdAt = new Date(createdPaperwork?.createdAt).toLocaleString()

            setPaperworks(state => [...state, createdPaperwork]);

            navigate('/paperworks');
        } catch (error) {
            setPaperworkAlertMessage(error.message);
        }
    };

    const onEditPaperworkSubmit = async (values) => {
        try {
            const result = await paperworkService.edit(values._id, values);

            setPaperworks(state => state.map(x => x._id === values._id ? {
                ...result?.paperwork,
                id: result?.paperwork?._id,
                expiryDate: new Date(result?.paperwork?.expiryDate).toDateString(),
                createdAt: new Date(result?.paperwork?.createdAt).toLocaleString()
            } : x));

            navigate(`/paperworks/`);
        } catch (error) {
            setPaperworkAlertMessage(error.message);
        }
    };

    const deletePaperwork = async (paperworkObject) => {
        await paperworkService.delete(paperworkObject._id)

        setPaperworks(state => state.filter(paperwork => paperwork._id !== paperworkObject._id));

        navigate(`/paperworks/`);
    };

    const getPaperwork = (paperworkId) => {
        return paperworks.find(paperwork => paperwork._id === paperworkId);
    };

    const contextValues = {
        paperworks,
        paperworkAlertMessage,
        setPaperworkAlertMessage,
        onCreatePaperworkSubmit,
        onEditPaperworkSubmit,
        deletePaperwork,
        getPaperwork,
    }

    return (
        <PaperworkContext.Provider value={contextValues}>
            {children}
        </PaperworkContext.Provider>
    );
};

export const usePaperworkContext = () => {
    const context = useContext(PaperworkContext);

    return context;
}