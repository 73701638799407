export const QuoteDialog = ({ location, starts, time, quoteServiceName, quoteItems, itemsRelated, description, manHours, hourlyRate, totalCharge, updatedAt, createdAt }) => {
    return (
        <>
            <p><strong>{location}</strong></p>
            <p><strong>{starts}, {time}</strong></p>
            <hr />
            <p>Details: <strong>{description}</strong></p>
            <hr />
            <p><strong>{quoteServiceName}</strong></p>
            {itemsRelated && <p>Extras: <strong>{itemsRelated?.map(item =>
                <>
                    <p><strong>{item.item}</strong></p>
                    <p>{item.description}</p>
                    <p>Man-Hours: <strong>{Number(item.manHours)?.toFixed(2)}</strong></p>
                    <p>Extra Charge: <strong>£{Number(item.totalCharge)?.toFixed(2)}</strong></p>
                    <hr />
                </>
            )}</strong></p>}
            <p><strong>Summary</strong></p>
            <p>Man-Hours: <strong>{manHours}</strong></p>
            <p>Hourly Rate: <strong>£{hourlyRate}</strong></p>
            <p>Total Charge: <strong>£{totalCharge}</strong></p>
            <hr />
            <p>Last Modified: <strong>{(new Date(updatedAt)).toDateString()}</strong></p>
            <p>Created At: <strong>{(new Date(createdAt)).toDateString()}</strong></p>
        </>
    );
};