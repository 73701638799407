import { useState, useEffect, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { invoiceServiceFactory } from '../services/invoiceService';

export const InvoiceContext = createContext();

export const InvoiceProvider = ({
    children,
}) => {
    const navigate = useNavigate();
    const [invoices, setInvoices] = useState([]);
    const invoiceService = invoiceServiceFactory();
    const [invoiceAlertMessage, setInvoiceAlertMessage] = useState('');

    useEffect(() => {
        invoiceService.getAll()
            .then(result => {
                result?.invoices?.map(function (invoice) {
                    invoice.id = invoice._id
                    invoice.label = invoice.platform
                    invoice.createdAt = new Date(invoice.createdAt).toLocaleString()
                    return invoice
                })
                setInvoices(result.invoices);
            });
        // eslint-disable-next-line
    }, []);

    const onCreateInvoiceSubmit = async (data) => {
        try {
            const createdInvoice = await invoiceService.create(data);
            createdInvoice.id = createdInvoice._id
            createdInvoice.label = createdInvoice.title
            createdInvoice.createdAt = new Date(createdInvoice.createdAt).toLocaleString()

            setInvoices(state => [...state, createdInvoice]);

            navigate(`/invoices`);
        } catch (error) {
            setInvoiceAlertMessage(error.message)
        }
    };

    const onEditInvoiceSubmit = async (values) => {
        try {
            const result = await invoiceService.edit(values._id, values);

            setInvoices(state => state.map(x => x._id === values._id ? { ...result?.invoice, id: result?.invoice?._id } : x));

            navigate(`/invoices/`);
        } catch (error) {
            setInvoiceAlertMessage(error.message);
        }
    };

    const deleteInvoice = async (invoiceObject) => {
        await invoiceService.delete(invoiceObject._id)

        setInvoices(state => state.filter(invoice => invoice._id !== invoiceObject._id));

        navigate(`/invoices/`);
    };

    const getInvoice = (invoiceId) => {
        return invoices.find(invoice => invoice._id === invoiceId);
    };

    const contextValues = {
        invoices,
        invoiceAlertMessage,
        setInvoiceAlertMessage,
        onCreateInvoiceSubmit,
        onEditInvoiceSubmit,
        deleteInvoice,
        getInvoice,
    }

    return (
        <InvoiceContext.Provider value={contextValues}>
            {children}
        </InvoiceContext.Provider>
    );
};

export const useInvoiceContext = () => {
    const context = useContext(InvoiceContext);

    return context;
}