import { useEffect, useState } from 'react';

import { useAuthContext } from '../../contexts/AuthContext';
import { useEventContext } from '../../contexts/EventContext';

import { usePeriods } from '../../hooks/usePeriods';
import { useLink } from '../../hooks/useLink';

import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PauseIcon from '@mui/icons-material/Pause';
import RemoveIcon from '@mui/icons-material/Remove';
import Title from '../Dashboard/Title';

import { monthPeriods } from '../../utils/mappers';
import { addDays } from '../../utils/dateUtils';

export default function Summary({
  isAdmin
}) {

  const [cleanings, setCleanings] = useState([]);

  const { events, getEvents } = useEventContext();

  const navigate = useLink();

  // Hooks for Personalization, Period Management and Values Calculations
  const { session } = useAuthContext();
  const { date, period, monthName } = usePeriods();

  useEffect(() => {
    if (session) {
      getEvents(period);
    }
    // eslint-disable-next-line
  }, [session, period]);

  useEffect(() => {
    // Refactor to request the events optimally instead of getting all and filtering in-memory
    setCleanings(events?.filter(event => (new Date(date.getFullYear(), date.getMonth(), monthPeriods[date.getMonth()] + 1) > new Date(event?.start?.dateTime) && new Date(event?.start?.dateTime)) > new Date(date.getFullYear(), date.getMonth(), 1)))
    // eslint-disable-next-line
  }, [events])

  const earnings = cleanings?.map(event => Number(event?.extendedProperties?.private?.totalCharge))?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  )?.toFixed(2) || '0.00'

  const earningsToday = cleanings
    ?.filter(event => (addDays(new Date(), 2) > new Date(event?.start?.dateTime) && new Date(event?.start?.dateTime)) > new Date())
    ?.map(event => Number(event?.extendedProperties?.private?.totalCharge))?.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    )?.toFixed(2) || '0.00'

  const payable = cleanings?.map(event => Number(event?.extendedProperties?.private?.cleanersPay))?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  )?.toFixed(2) || '0.00'

  const cashReceived = cleanings?.map(event => Number(event?.extendedProperties?.private?.cashReceived))?.reduce(
    (accumulator, currentValue) => accumulator + currentValue,
    0
  )?.toFixed(2) || '0.00'

  return (
    <>
      <Title>{monthName} Summary</Title>

      <Typography color="text.secondary">
        Earnings
      </Typography>
      <Typography component="p" variant="h5" sx={{ flex: 1, justifyContent: 'flex-start' }}>
        £{isAdmin ? earnings : payable}
      </Typography>

      <Typography component="p" variant="h4" sx={{ flex: 1 }}>
        <RemoveIcon />
      </Typography>
      <Typography color="text.secondary">
        Deductable
      </Typography>
      <Typography component="p" variant="h5" sx={{ flex: 1 }}>
        £{isAdmin ? earningsToday : cashReceived}
      </Typography>

      <Typography component="p" variant="h4" sx={{ flex: 1 }}>
        <PauseIcon />
      </Typography>
      <Typography color="text.secondary">
        Total
      </Typography>
      <Typography component="p" variant="h5" sx={{ flex: 1 }}>
        £{isAdmin ? Number(earnings - Number(earningsToday))?.toFixed(2) : payable - cashReceived}
      </Typography>
      <Typography color="text.secondary" sx={{ mt: 2, mb: 1 }} >
        {(new Date()).toLocaleString("en-GB", { day: 'numeric', month: 'long', hour: 'numeric', minute: 'numeric', year: 'numeric' })}
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={navigate}>
          More Details
        </Link>
      </div>
    </>
  );
}