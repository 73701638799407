import * as React from 'react';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title';
import { useQuoteContext } from '../../contexts/QuoteContext';
import { useServiceContext } from '../../contexts/ServiceContext';
import { useItemContext } from '../../contexts/ItemContext';
import { useLink } from '../../hooks/useLink';


export default function Quotes() {
    const { quotes } = useQuoteContext();
    const { services } = useServiceContext();
    const { items } = useItemContext();

    const navigate = useLink();

    return (
        <>
            <Title>Recent Quotes</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell align="right">Price</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {quotes?.map((quote) => (
                        <TableRow key={quote._id}>
                            <TableCell>{new Date(quote?.start?.dateTime)?.toLocaleString('en-gb', { month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' })}</TableCell>
                            <TableCell>{quote.location}</TableCell>
                            <TableCell align="right">£{
                                items
                                    ?.filter(item => quote?.items?.includes(item._id))
                                    ?.map(item => item.totalCharge)
                                    ?.reduce(
                                        (accumulator, currentValue) => accumulator + currentValue,
                                        Number(services?.filter(service => service._id === quote.service)?.map(service => service.totalCharge))
                                    ) || ''
                            }</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <Link color="primary" href="/quotes" onClick={navigate} sx={{ mt: 3 }}>
                Open quotes table
            </Link>
        </>
    );
}