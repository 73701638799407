export const useError = (formErrors, setFormErrors, values, now, max) => {

    const formValidate = (e) => {
        const value = e?.target?.value;
        const target = e?.target?.name;

        // Event
        if (target === 'summary' && (value.length < 3 || value.length > 70)) {
            formErrors.summary = 'Summary must be between 3 and 70 characters!';
        }
        else if (target === 'location' && (value.length < 2 || value.length > 150)) {
            formErrors.location = 'Location must be between 2 and 150 characters!';
        }
        else if (target === 'address' && (value.length < 2 || value.length > 150)) {
            formErrors.address = 'Address must be between 2 and 150 characters!';
        }
        // else if (target === 'team' && (value.length < 1)) {
        //     formErrors.team = 'You must assign at least 1 Service Provider!';
        // }
        // else if (target === 'description' && (value.length < 5 || value.length > 700)) {
        //     formErrors.description = 'Event Description must be between 5 and 700 characters!';
        // }
        // else if (target === 'email' && (value.length < 3 || value.length > 26)) {
        //     formErrors.email = 'Email must be between 3 and 26 characters!';
        // } 
        // else if (target === 'email' && !(/^[A-Za-z0-9_.]+@[A-Za-z]+.[A-Za-z.]{2,5}$/.test(value))) {
        //     formErrors.email = 'Enter a valid Email address!';
        // }
        else if (target === 'team' && (value.length === 0)) {
            formErrors.team = 'Please select Team';
        }
        else if (target === 'start' && (value <= now)) {
            formErrors.start = 'Scheduled date must be in the future!';
        }
        else if (target === 'start' && (value > max)) {
            formErrors.start = 'Scheduled date must be in the near future!';
        }

        // else if (target === 'start' && (value > values.end)) {
        //     formErrors.start = 'Start must be before the end of the event!';
        // } 

        // else if (target === 'end' && (value <= now)) {
        //     formErrors.end = 'Scheduled date must be in the future!';
        // }
        // else if (target === 'end' && (value > max)) {
        //     formErrors.end = 'Scheduled date must be in the near future!';
        // }
        // else if (target === 'end' && (value < values.start)) {
        //     formErrors.end = 'End must be after the start of the event!';
        // }

        // Service && Item
        else if (target === 'service' && (value.length < 3 || value.length > 40)) {
            formErrors.service = 'Service title must be between 3 and 40 characters!!';
        }
        else if (target === 'item' && (value.length < 3 || value.length > 40)) {
            formErrors.item = 'Item title must be between 3 and 40 characters!!';
        }
        else if (target === 'description' && (value.length > 700)) {
            formErrors.description = 'Description must be maximum 700 characters!';
        }
        else if (target === 'price' && (Number(value) < 0 || isNaN(value) || !value)) {
            formErrors.price = 'Price must be a positive number!';
        }
        else if (target === 'manHours' && (Number(value) < 0 || isNaN(value) || !value)) {
            formErrors.manHours = 'Man-Hours must be a positive number!';
        }
        else if (target === 'hourlyRate' && (Number(value) < 0 || isNaN(value) || !value)) {
            formErrors.hourlyRate = 'Hourly Rate must be a positive number!';
        }
        else if (target === 'totalCharge' && (Number(value) < 0 || isNaN(value) || !value)) {
            formErrors.totalCharge = 'Total Charge must be a positive number!';
        }
        else if (target === 'cleanersRate' && (Number(value) < 0 || isNaN(value) || !value)) {
            formErrors.cleanersRate = 'Cleaners Rate must be a positive number!';
        }
        else if (target === 'cleanersPay' && (Number(value) < 0 || isNaN(value) || !value)) {
            formErrors.cleanersPay = 'Cleaners Pay must be a positive number!';
        }

        // Worker && Client
        else if (target === 'firstName' && (value.length < 1 || value.length > 37)) {
            formErrors.firstName = 'First Name must be between 1 and 37 characters!';
        }
        else if (target === 'lastName' && (value.length < 1 || value.length > 37)) {
            formErrors.lastName = 'Last Name must be between 1 and 37 characters!';
        }
        else if (target === 'email' && (value.length < 7 || value.length > 49)) {
            formErrors.email = 'Email must be between 7 and 49 characters!';
        }
        else if (target === 'email' && !(/^[A-Za-z0-9_.]+@[A-Za-z]+.[A-Za-z.]{2,49}$/.test(value))) {
            formErrors.email = 'Enter a valid Email address!';
        }
        // else if (target === 'imageUrl' && !(/^https?:\/\/.+/.test(value))) {
        //     formErrors.imageUrl = 'Enter a valid URL address!';
        // }
        else if (target === 'phoneNumber' && !(/^0[1-9]{1}[0-9]{9}$/.test(value))) {
            formErrors.phoneNumber = 'Phone number must start with 0 followed by 10 digits!';
        }
        // else if (target === 'phoneNumber' && (value.length !== 11)) {
        //     formErrors.phoneNumber = 'Phone number must be 11 digits!';
        // }

        // Invoicing Platform
        else if (target === 'platform' && (value.length < 2 || value.length > 40)) {
            formErrors.platform = 'Invoicing platform must be between 2 and 40 characters!';
        }

        // Clearing errors
        else {
            formErrors[target] = ''
        }
        setFormErrors(state => ({ ...state, ...formErrors }));
    };

    return {
        formValidate
    };
}