import { requestFactory } from "./requester"

const baseUrl = process.env.REACT_APP_ENV_HERE === "production"
? 'https://titan-cleaners-be.onrender.com/api/invoices'
: 'http://localhost:3005/api/invoices'

export const invoiceServiceFactory = (token) => {
    const request = requestFactory();

    const getAll = async () => {
        const result = await request.get(`${baseUrl}`);
    
        return result;
    };

    const getOne = async (invoiceId) => {
        const result = await request.get(`${baseUrl}/${invoiceId}`);

        return result.invoice;
    };

    const create = async (invoiceData) => {
        const result = await request.post(baseUrl, invoiceData);

        return result.invoice;
    };

    const edit = (invoiceId, data) => request.put(`${baseUrl}/${invoiceId}`, data);

    const deleteInvoice = (invoiceId) => request.delete(`${baseUrl}/${invoiceId}`); 

    return {
        getAll,
        getOne,
        create,
        edit,
        delete: deleteInvoice,
    };
};
