import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';
import { useService } from '../../hooks/useService';

import EditIcon from '@mui/icons-material/Edit';
import {
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    FormControl,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Alert,
    Snackbar,
} from '@mui/material'

import { useClientContext } from '../../contexts/ClientContext';
import { clientServiceFactory } from '../../services/clientService';

export const EditClient = () => {
    const { clientId } = useParams();
    const clientService = useService(clientServiceFactory);
    const { clientAlertMessage, setClientAlertMessage, onEditClientSubmit } = useClientContext();

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        bedConfig: '',
    };

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        bedConfig: '',
    });

    const { values, changeHandler, onSubmit, changeValues } = useForm(initialValues, onEditClientSubmit);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    useEffect(() => {
        clientService.getOne(clientId)
            .then(result => {
                changeValues(result);
            });
        // eslint-disable-next-line
    }, [clientId]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <EditIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Edit Client Details
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.firstName}
                        margin="normal"
                        required

                        error={formErrors.firstName}
                        helperText={formErrors.firstName}
                        fullWidth
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        autoComplete="name"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.lastName}
                        margin="normal"
                        required
                        error={formErrors.lastName}
                        helperText={formErrors.lastName}
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lastName"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.email}
                        margin="normal"
                        required
                        error={formErrors.email}
                        helperText={formErrors.email}
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.phoneNumber}
                        margin="normal"
                        required
                        error={formErrors.phoneNumber}
                        helperText={formErrors.phoneNumber}
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        id="phoneNumber"
                        label="Phone Number"
                        name="phoneNumber"
                        autoComplete="phone"
                    />

                    <FormControl>
                        <FormLabel id="demo-controlled-radio-buttons-group">Bed Configurations</FormLabel>
                        <RadioGroup
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            row
                            value={values.bedConfig}
                            onChange={(e) => changeValues({ ...values, bedConfig: e?.target?.value })}
                        >
                            <FormControlLabel value="false" control={<Radio />} label="No" />
                            <FormControlLabel value="true" control={<Radio />} label="Yes" />
                        </RadioGroup>
                    </FormControl>

                    {clientAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setClientAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {clientAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                    {/* <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2">
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>
                </Grid> */}
                </Box>
            </Box>
        </Container >
    );
};