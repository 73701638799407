import { useState } from 'react';
import { hasEmptyProperty } from '../utils/validators';

export const useForm = (initialValues, onSubmitHandler) => {
    const [values, setValues] = useState(initialValues);

    const changeHandler = (e) => {
        setValues(state => ({...state, [e?.target?.name]: e?.target?.value}))
    };

    const onSubmit = (e) => {
        e.preventDefault();

        if (values?.summary === '') {
            values.summary = 'No Title';
        };

        if (values?.location === '') {
            values.location = 'No Location';
        };

        if (values?.interval === '') {
            values.interval = 'No Recurrence';
        };

        if (values?.recurrence === '') {
            values.recurrence = false;
        };

        if (values?.company === '') {
            values.company = ' ';
        };

        if (values?.description === '') {
            values.description = ' ';
        };

        if (values?.team?.length === 0) {
            values.team = '';
        };

        if (hasEmptyProperty(values)) {
            return alert('All fields are required!');
        };

        onSubmitHandler(values);
        
        // Clears the form even if it doesn't send successfully
        // setValues(initialValues);
    };

    const changeValues = (newValues) => {
        setValues(newValues);
    };

    return {
        values,
        changeHandler,
        onSubmit,
        changeValues,
    };
}