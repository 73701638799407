import { useState, useEffect, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { itemServiceFactory } from '../services/itemService';

export const ItemContext = createContext();

export const ItemProvider = ({
    children,
}) => {
    const navigate = useNavigate();
    const [items, setItems] = useState([]);
    const itemService = itemServiceFactory();
    const [itemAlertMessage, setItemAlertMessage] = useState('');

    useEffect(() => {
        itemService.getAll()
            .then(result => {
                result?.items?.map(function (item) {
                    item.id = item._id
                    item.label = item.item
                    item.createdAt = new Date(item.createdAt).toLocaleString()
                    return item
                })
                setItems(result.items);
            });
        // eslint-disable-next-line
    }, []);

    const onCreateItemSubmit = async (data) => {
        try {
            const createdItem = await itemService.create(data);
            createdItem.id = createdItem._id
            createdItem.label = createdItem.item
            createdItem.createdAt = new Date(createdItem.createdAt).toLocaleString()

            setItems(state => [...state, createdItem]);
            // Possible-Bug The createdItem seems to be not in the state yer
            console.log(items)

            navigate('/items');
        } catch (error) {
            setItemAlertMessage(error.message);
        }
    };

    const onEditItemSubmit = async (values) => {
        try {
            const result = await itemService.edit(values._id, values);

            setItems(state => state.map(x => x._id === values._id ? { ...result?.item, id: result?.item?._id } : x));

            navigate(`/items`);
        } catch (error) {
            setItemAlertMessage(error.message);
        }
    };

    const deleteItem = async (itemObject) => {
        await itemService.delete(itemObject._id)

        setItems(state => state.filter(item => item._id !== itemObject._id));

        navigate(`/items`);
    };

    const getItem = (itemId) => {
        return items.find(item => item._id === itemId);
    };

    const contextValues = {
        items,
        itemAlertMessage,
        setItemAlertMessage,
        onCreateItemSubmit,
        onEditItemSubmit,
        deleteItem,
        getItem,
    }

    return (
        <ItemContext.Provider value={contextValues}>
            {children}
        </ItemContext.Provider>
    );
};

export const useItemContext = () => {
    const context = useContext(ItemContext);

    return context;
}