import { useAuthContext } from "../../contexts/AuthContext";
import { useClientContext } from "../../contexts/ClientContext";

import { useLink } from "../../hooks/useLink";

import { Typography, Button, IconButton, Link } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import BedIcon from '@mui/icons-material/Bed';
import LinearProgress from '@mui/material/LinearProgress';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

import { Details } from "../Details/Details";


export const ClientTable = () => {
    const { isAdmin } = useAuthContext();
    const { clients } = useClientContext();

    const navigate = useLink();

    const columns = [
        {
            field: 'actions', headerName: 'Actions', width: 100,
            renderCell: (params) =>
                <>
                    <Link title='Delete' href={`/clients/${params.id}/delete`} onClick={(e) => navigate(e, `/clients/${params.id}/delete`)} sx={{ color: "text.secondary" }}><DeleteIcon /></Link>
                    <Link title='Edit' href={`/clients/${params.id}/edit`} onClick={(e) => navigate(e, `/clients/${params.id}/edit`)} sx={{ color: "text.secondary" }}><EditIcon /></Link>
                    <IconButton aria-label="comment">
                        <Details key={params.row.id} {...params.row} client />
                    </IconButton>
                </>
        },
        { field: 'firstName', headerName: 'Name', width: 150 },
        { field: 'lastName', headerName: 'Surname', width: 150 },
        { field: 'company', headerName: 'Copmany', width: 150 },
        { field: 'email', headerName: 'Email', width: 150 },
        { field: 'phoneNumber', headerName: 'Phone', width: 150 },
        {
            field: 'bedConfig', headerName: 'Bed Configuratons', width: 150,
            sortable: false,
            renderCell: (params) => params.value ? <BedIcon /> : ''
        },
        { field: 'createdAt', headerName: 'Created At', width: 175 },
    ];

    return (
        <>
            <Typography
                variant="h5"
                component='h5'
                sx={{
                    textAlign: 'center', mt: 3, mb: 3,
                }}
            >
                Clients List
            </Typography>
            {isAdmin &&
                <Button size="small" onClick={(e) => navigate(e, '/add-client')}>
                    Add Client
                </Button>
            }
            <DataGrid
                columns={columns}
                rows={clients}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress,
                }}
                slotProps={{
                    columnMenu: { background: 'red', counter: clients.length },
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </>
    );
};