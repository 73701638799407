
import { useAuthContext } from '../../contexts/AuthContext';

import { useLink } from '../../hooks/useLink';

import { ButtonGroup, Link } from '@mui/material';

import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import HomeIcon from '@mui/icons-material/Home';
import HowToRegIcon from '@mui/icons-material/HowToReg';
// import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
// import PeopleIcon from '@mui/icons-material/People';
// import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
// import RoomServiceIcon from '@mui/icons-material/RoomService';
// import ExtensionIcon from '@mui/icons-material/Extension';
// import EngineeringIcon from '@mui/icons-material/Engineering';
// import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
// import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
// import ReceiptIcon from '@mui/icons-material/Receipt';
import LogoutIcon from '@mui/icons-material/Logout';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import LoginIcon from '@mui/icons-material/Login';
import PersonIcon from '@mui/icons-material/Person';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';


export const Navigation = () => {
    const { isAdmin, isAuthenticated } = useAuthContext();

    const navigate = useLink();

    return (
        <nav>
            <ButtonGroup variant="contained" aria-label="outlined primary button group">
                <Stack direction="row" spacing={1}>
                    {isAdmin ?
                        <Link href="/dashboard" onClick={(e) => navigate(e, '/dashboard')} >
                            <IconButton color="white" aria-label="Dashboard">
                                <HomeIcon />
                            </IconButton>
                        </Link>
                        :
                        <Link href="/" onClick={(e) => navigate(e, '/')} >
                            <IconButton color="white" aria-label="Home">
                                <HomeIcon />
                            </IconButton>
                        </Link>
                    }

                    {/* <Link href="/about" onClick={navigate} >
                        <IconButton color="white" aria-label="add to shopping cart">
                            <InfoIcon />
                        </IconButton>
                    </Link> */}

                    {isAdmin &&
                        <>
                            {/* <Link href="/dashboard" onClick={navigate} >
                                <IconButton color="white" aria-label="Dashboard">
                                    <DashboardIcon />
                                </IconButton>
                            </Link> */}

                            <Link href="/calendar" onClick={(e) => navigate(e, '/calendar')} >
                                <IconButton color="white" aria-label="Onvoicing Platforms Table">
                                    <CalendarMonthIcon />
                                </IconButton>
                            </Link>

                            {/* <Link href="/events" onClick={navigate} >
                                <IconButton color="white" aria-label="Events Table">
                                    <CleaningServicesIcon />
                                </IconButton>
                            </Link> */

                            /* <Link href="/quotes" onClick={navigate} >
                                <IconButton color="white" aria-label="Quotes Table">
                                    <RequestQuoteIcon />
                                </IconButton>
                            </Link>


                            <Link href="/clients" onClick={navigate} >
                                <IconButton color="white" aria-label="Clients Table">
                                    <PeopleIcon />
                                </IconButton>
                            </Link>


                            <Link href="/services" onClick={navigate} >
                                <IconButton color="white" aria-label="Services Table">
                                    <RoomServiceIcon />
                                </IconButton>
                            </Link>


                            <Link href="/items" onClick={navigate} >
                                <IconButton color="white" aria-label="Items Table">
                                    <ExtensionIcon />
                                </IconButton>
                            </Link>

                            <Link href="/workers" onClick={navigate} >
                                <IconButton color="white" aria-label="Workers Table">
                                    <EngineeringIcon />
                                </IconButton>
                            </Link>

                            <Link href="/paperworks" onClick={navigate} >
                                <IconButton color="white" aria-label="Paperworks Table">
                                    <InsertDriveFileIcon />
                                </IconButton>
                            </Link>

                            <Link href="/vehicles" onClick={navigate} >
                                <IconButton color="white" aria-label="Vehicles Table">
                                    <DirectionsCarIcon />
                                </IconButton>
                            </Link>

                            <Link href="/invoices" onClick={navigate} >
                                <IconButton color="white" aria-label="Onvoicing Platforms Table">
                                    <ReceiptIcon />
                                </IconButton>
                            </Link> */}
                        </>
                    }

                    {!isAdmin && isAuthenticated &&
                        < Link href="/profile" onClick={(e) => navigate(e, '/profile')}>
                            <IconButton color="white" aria-label="User Profile">
                                <PersonIcon />
                            </IconButton>
                        </Link>
                    }

                    {isAuthenticated &&
                        <Link href="/logout" onClick={(e) => navigate(e, '/logout')} >
                            <IconButton color="white" aria-label="Logout">
                                <LogoutIcon />
                            </IconButton>
                        </Link>
                    }

                    {!isAuthenticated &&
                        <>
                            <Link href="/reqiest-cleaning" onClick={(e) => navigate(e, '/request-cleaning')} >
                                <IconButton color="white" aria-label="request-cleaning">
                                    <AddLocationAltIcon />
                                </IconButton>
                            </Link>

                            <Link href="/login" onClick={(e) => navigate(e, '/login')} >
                                <IconButton color="white" aria-label="Login">
                                    <HowToRegIcon />
                                </IconButton>
                            </Link>

                            {/* Google Identity Service GIS Login */}
                            {/* <Link href="/gis" onClick={navigate} >
                                <IconButton color="white" aria-label="Google Identity Service">
                                    <LoginIcon />
                                </IconButton>
                            </Link> */}

                            {/* <Link href="/register" onClick={navigate} >
                                <IconButton color="white" aria-label="Register">
                                    <PersonAddAltIcon />
                                </IconButton>
                            </Link> */}
                        </>
                    }

                </Stack>
            </ButtonGroup>
        </nav >
    );
};