import { useState } from 'react';

import CleaningServicesIcon from '@mui/icons-material/CleaningServices';
import {
    Autocomplete,
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
} from '@mui/material'

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';


import { useServiceContext } from '../../contexts/ServiceContext';
import { useItemContext } from '../../contexts/ItemContext';
import { useInvoiceContext } from '../../contexts/InvoiceContext';


export const Service = () => {
    const { serviceAlertMessage, setServiceAlertMessage, onCreateServiceSubmit } = useServiceContext();
    const { items } = useItemContext();
    const { invoices } = useInvoiceContext();

    const [invoiceValue, setInvoiceValue] = useState('')

    const initialValues = {
        service: '',
        description: '',
        price: '',
        manHours: '',
        items: [],
        hourlyRate: '',
        totalCharge: '',
        cleanersRate: '',
        cleanersPay: '',
        invoicingPlatform: [],
    };

    const [formErrors, setFormErrors] = useState({
        service: '',
        description: '',
        price: '',
        manHours: '',
        items: '',
        hourlyRate: '',
        totalCharge: '',
        cleanersRate: '',
        cleanersPay: '',
        invoicingPlatform: '',
    });

    const { values, changeHandler, onSubmit, changeValues } = useForm(initialValues, onCreateServiceSubmit);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    // useEffect(() => {
    //     changeValues({
    //         ...values,
    //         totalPrice: values.items?.
    //             map(item => item.totalCharge)?.
    //             reduce(
    //                 (accumulator, currentValue) => accumulator + currentValue,
    //                 Number(values.service.totalCharge)
    //             ),
    //         totalTime: values.items?.
    //             map(item => item.manHours)?.
    //             reduce(
    //                 (accumulator, currentValue) => accumulator + currentValue,
    //                 Number(values.service.manHours)
    //             )
    //     })
    // }, [values.items])

    // TODO remove, this demo shouldn't need to reset the theme.

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <CleaningServicesIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    New Service
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.service}
                        helperText={formErrors.service}
                        value={values.service}
                        margin="normal"
                        required
                        fullWidth
                        id="service"
                        label="Service Name"
                        name="service"
                        autoComplete="service"
                        autoFocus
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.description}
                        helperText={formErrors.description}
                        value={values.description}
                        margin="normal"
                        multiline
                        rows={4}
                        // required
                        fullWidth
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete="description"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.price}
                        helperText={formErrors.price}
                        value={values.price}
                        margin="normal"
                        required
                        fullWidth
                        id="price"
                        label="Price"
                        name="price"
                        autoComplete="price"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.manHours}
                        helperText={formErrors.manHours}
                        value={values.manHours}
                        margin="normal"
                        required
                        fullWidth
                        id="manHours"
                        label="Man-Hours"
                        name="manHours"
                        autoComplete="manHours"
                    />
                    {items.length > 0 && <Autocomplete
                        multiple
                        value={values.items}
                        onChange={(event, newValue) => {
                            changeValues({ ...values, items: newValue })
                        }}
                        options={items}
                        // id="service-items"
                        // getOptionLabel={(option) => option.title}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                margin='normal'
                                {...params}
                                label="Service Items"
                                placeholder="+ Add"
                            />
                        )}
                    />
                    }
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.hourlyRate}
                        helperText={formErrors.hourlyRate}
                        value={values.hourlyRate}
                        margin="normal"
                        required
                        fullWidth
                        id="hourlyRate"
                        label="Hourly Rate"
                        name="hourlyRate"
                        autoComplete="hourlyRate"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.totalCharge}
                        helperText={formErrors.totalCharge}
                        value={values.totalCharge}
                        margin="normal"
                        required
                        fullWidth
                        id="totalCharge"
                        label="Total Charge"
                        name="totalCharge"
                        autoComplete="totalCharge"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.cleanersRate}
                        helperText={formErrors.cleanersRate}
                        value={values.cleanersRate}
                        margin="normal"
                        required
                        fullWidth
                        id="cleanersRate"
                        label="Cleaners Rate"
                        name="cleanersRate"
                        autoComplete="cleanersRate"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.cleanersPay}
                        helperText={formErrors.cleanersPay}
                        value={values.cleanersPay}
                        margin="normal"
                        required
                        fullWidth
                        id="cleanersPay"
                        label="Cleaners Pay"
                        name="cleanersPay"
                        autoComplete="cleanersPay"
                    />

                    <Autocomplete
                        // onChange={changeHandler}
                        value={values.invoicingPlatform}
                        onChange={(event, newValue) => {
                            values.invoicingPlatform = newValue
                        }}
                        inputValue={invoiceValue}
                        onInputChange={(event, newInputValue) => {
                            // changeValues({...values, invoicingPlatform: newInputValue});
                            setInvoiceValue(newInputValue)
                        }}
                        fullWidth
                        required
                        disablePortal
                        id="invoicingPlatform"
                        label="Invoicing Platform"
                        name="invoicingPlatform"
                        options={invoices}
                        renderInput={(params) => <TextField
                            margin='normal'
                            {...params}
                            label="Invoicing *"
                        />}
                    />
                    {serviceAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setServiceAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {serviceAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
        </Container >
    );
};