import { requestFactory } from "./requester"

const baseUrl = process.env.REACT_APP_ENV_HERE === "production"
? 'https://titan-cleaners-be.onrender.com/api/services'
: 'http://localhost:3005/api/services'

export const serviceServiceFactory = (token) => {
    const request = requestFactory();

    const getAll = async () => {
        const result = await request.get(`${baseUrl}`);
    
        return result;
    };

    const getOne = async (serviceId) => {
        const result = await request.get(`${baseUrl}/${serviceId}`);

        return result.service;
    };

    const create = async (serviceData) => {
        const result = await request.post(baseUrl, serviceData);

        return result.service;
    };

    const edit = (serviceId, data) => request.put(`${baseUrl}/${serviceId}`, data);

    const deleteService = (serviceId) => request.delete(`${baseUrl}/${serviceId}`); 

    return {
        getAll,
        getOne,
        create,
        edit,
        delete: deleteService,
    };
};
