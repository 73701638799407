import { useState } from 'react';
import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';

import { useInvoiceContext } from '../../contexts/InvoiceContext';
import ReceiptIcon from '@mui/icons-material/Receipt';

import {
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
} from '@mui/material'

export const Invoice = () => {
    const { invoiceAlertMessage, setInvoiceAlertMessage, onCreateInvoiceSubmit } = useInvoiceContext();
    const initialValues = {
        platform: '',
    }
    const [formErrors, setFormErrors] = useState({
        platform: '',
    });

    const { values, changeHandler, onSubmit } = useForm(initialValues, onCreateInvoiceSubmit);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <ReceiptIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Add an Invoicing Platform
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.platform}
                        helperText={formErrors.platform}
                        value={values.platform}
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        id="platform"
                        label="Invoicing Platform"
                        name="platform"
                        autoComplete="platform"
                    />
                    {invoiceAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setInvoiceAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {invoiceAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                </Box>
            </Box>
        </Container >
    );
};