import { Typography, Link } from "@mui/material";

import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import YouTubeIcon from '@mui/icons-material/YouTube';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';

export const Copyright = ({ todo }) => {
    return (
        <div data-testid="copyright">
            <Typography variant="body2" color="icons" align="center"
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    justifyItems: 'center',
                    gap: 0.75,
                }}>
                <Link color="icons.github" href="https://github.com/">
                    <GitHubIcon />
                </Link>
                <Link color="icons.youtube" href="https://youtube.com/">
                    <YouTubeIcon />
                </Link>
                <Link color="icons.whatsapp" href="https://whatsapp.com/">
                    <WhatsAppIcon />
                </Link>
                <Link color="icons.linkedin" href="https://www.linkedin.com/">
                    <LinkedInIcon />
                </Link>
                <Link color="icons.facebook" href="https://www.facebook.com/">
                    <FacebookIcon />
                </Link>
                <Link color="icons.telegram" href="https://t.me/">
                    <TelegramIcon />
                </Link>
                <Link color="icons.twitter" href="https://twitter.com/">
                    <TwitterIcon />
                </Link>
            </Typography>

            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://titan-cleaners.co.uk/"
                    sx={{
                        textDecoration: 'none'
                    }}>
                    Titan Cleaners
                </Link>{' '}
                {new Date().getFullYear()}.
            </Typography>
        </div>

    );
}