export const PaperworkDialog = ({ title, description, expiryDate, updatedAt, createdAt }) => {
    return (
        <>
            <p><strong>{title}</strong></p>
            <p>{description}</p>
            <hr />
            <p>Expiry Date: <strong>{(new Date(expiryDate)).toDateString()}</strong></p>
            <hr />
            <p>Last Modified: <strong>{(new Date(updatedAt)).toDateString()}</strong></p>
            <p>Created At: <strong>{(new Date(createdAt)).toDateString()}</strong></p>
        </>
    );
};