import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';
import { useService } from '../../hooks/useService';

import DeleteIcon from '@mui/icons-material/Delete';
import {
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
} from '@mui/material'

import { useInvoiceContext } from '../../contexts/InvoiceContext';
import { invoiceServiceFactory } from '../../services/invoiceService';

export const DeleteInvoice = () => {
    const { invoiceId } = useParams();
    const invoiceService = useService(invoiceServiceFactory);
    const { invoiceAlertMessage, setInvoiceAlertMessage, deleteInvoice } = useInvoiceContext();

    const initialValues = {
        platform: '',
    };

    const [formErrors, setFormErrors] = useState({
        platform: '',
    });

    const { values, changeHandler, onSubmit, changeValues } = useForm(initialValues, deleteInvoice);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    useEffect(() => {
        invoiceService.getOne(invoiceId)
            .then(result => {
                changeValues(result);
            });
        // eslint-disable-next-line
    }, [invoiceId]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <DeleteIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Delete Invoicing Platform
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.platform}
                        margin="normal"
                        required
                        disabled
                        error={formErrors.platform}
                        helperText={formErrors.platform}
                        fullWidth
                        id="platform"
                        label="Invoicing Platform"
                        name="platform"
                        autoComplete="name"
                    />

                    {invoiceAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setInvoiceAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {invoiceAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                </Box>
            </Box>
        </Container >
    );
};