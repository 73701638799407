import { useEventContext } from "../contexts/EventContext";
import { getClientName } from "../utils/textUtils";

export const usePicker = (type) => {
    const { populateFolder } = useEventContext();

    if (type === 'images') {
        return async function (object) {
            const { client, location, starts, id } = object
            const parentFolder = process.env.REACT_APP_IMAGES_FOLDER;

            const folderName = `${location ? location : getClientName(client)} - ${starts}`

            await populateFolder(folderName, parentFolder, id, type)
        }
    }

    if (type === 'paperworks') {
        return async function (object) {
            const parentFolder = object.type !== 'Insurance' ? process.env.REACT_APP_PAPERWORK_FOLDER : process.env.REACT_APP_FLEET_FOLDER

            const folderName = object.type !== 'Insurance' ? `${object.title ? object.title : object.label} - ${object.expiryDate}` : object.label ? object.label : `${object.brand} ${object.make} - ${object.plates}`

            await populateFolder(folderName, parentFolder, object.id, type)
        }
    }
};