import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { quoteServiceFactory } from '../services/quoteService';
import { clientServiceFactory } from '../services/clientService';
import { getDateTimeZone } from '../utils/dateUtils';

export const QuoteContext = createContext();

export const QuoteProvider = ({
    children,
}) => {
    const navigate = useNavigate();
    const quoteService = quoteServiceFactory();
    const clientService = clientServiceFactory();
    const [quotes, setQuotes] = useState([]);
    const [quoteAlertMessage, setQuoteAlertMessage] = useState('');
    const [newQuote, setNewQuote] = useState();


    const getAllQuotes = async () => {
        const AllQuotes = await quoteService.getAll();

        setQuotes(AllQuotes);
    };

    useEffect(() => {
        getAllQuotes()
        // eslint-disable-next-line
    }, []);

    const onCreateQuoteSubmit = async (data) => {
        try {
            const { firstName, lastName, email, phoneNumber, service, items, address, description, date } = data

            // Obviously, sometimes the client will be present in the database, so there should be a check by email and
            // before attempting to createa a new Client :)
            const getOrCreateClient = await clientService.create({ firstName, lastName, email, phoneNumber })

            const summary = `${address} - ${firstName} ${lastName}`
            const start = getDateTimeZone(date)

            const quoteData = { client: getOrCreateClient._id, service: service._id, items, summary, location: address, description, start }

            const createdQuote = await quoteService.create(quoteData);
            createdQuote.items = createdQuote.items?.map(item => item._id)

            setQuotes([...quotes, createdQuote])

            setNewQuote(createdQuote)

            navigate('/request-success');
        } catch (error) {
            setQuoteAlertMessage(error.message);
        }
    };

    const onEditQuoteSubmit = async (values) => {
        try {
            const { client, service, items, address, description, date } = values

            const start = getDateTimeZone(date)
            const summary = `${address} - ${client.firstName} ${client.lastName}`

            const quoteData = { client: client._id, service: service._id, summary, items, location: address, description, start }

            const result = await quoteService.edit(values._id, quoteData);


            setQuotes(state => state.map(x => x._id === values._id ? result?.quote : x));

            navigate(`/quotes/`);
        } catch (error) {
            setQuoteAlertMessage(error.message);
        }
    };

    const deleteQuote = async (quoteObject) => {
        await quoteService.delete(quoteObject._id)

        setQuotes(state => state.filter(quote => quote._id !== quoteObject._id));

        navigate(`/quotes/`);
    };

    const getQuote = (quoteId) => {
        return quotes?.find(quote => quote._id === quoteId);
    };

    const context = {
        quotes,
        setQuotes,
        newQuote,
        quoteAlertMessage,
        setQuoteAlertMessage,
        onCreateQuoteSubmit,
        onEditQuoteSubmit,
        deleteQuote,
        getQuote,
    }

    return (
        <QuoteContext.Provider value={context}>
            {children}
        </QuoteContext.Provider>
    );
};

export const useQuoteContext = () => {
    const context = useContext(QuoteContext);

    return context;
}
