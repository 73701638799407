export const ClientDialog = ({firstName, lastName, company, email, phoneNumber, bedConfig, updatedAt, createdAt}) => {
    return (
        <>
            <p><strong>{firstName} {lastName}</strong></p>
            {company && <p>Company: <strong>{company}</strong></p>}
            <hr />
            <p>Phone: <strong>{phoneNumber}</strong></p>
            <p>Email: <strong>{email}</strong></p>
            <hr />
            {bedConfig ? <strong>Needs Bed Configurations</strong> : <strong>No Bed Configurations</strong>}
            <hr />
            <p>Last Modified: <strong>{(new Date(updatedAt)).toDateString()}</strong></p>
            <p>Created At: <strong>{(new Date(createdAt)).toDateString()}</strong></p>
        </>
    );
};