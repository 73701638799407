import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PreviewIcon from '@mui/icons-material/Preview';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useDialog } from '../../hooks/useDialog';
import { Link, useTheme } from '@mui/material';
import { ImageList, ImageListItem } from '@mui/material';
import { ClientDialog } from '../Dialogs/ClientDialog';
import { InvoiceDialog } from '../Dialogs/InvoiceDialog';
import { WorkerDialog } from '../Dialogs/WorkerDialog';
import { getPaperworks } from '../../utils/paperworkUtils';
import { usePaperworkContext } from '../../contexts/PaperworkContext';
import { VehicleDialog } from '../Dialogs/VehicleDialog';
import { PaperworkDialog } from '../Dialogs/PaperworkDialog';
import { useItemContext } from '../../contexts/ItemContext';
import { ServiceDialog } from '../Dialogs/ServiceDialog';
import { getInvoicingDetails, getServiceItems } from '../../utils/textUtils';
import { ItemDialog } from '../Dialogs/ItemDialog';
import { QuoteDialog } from '../Dialogs/QuoteDialog';
import { EventDialog } from '../Dialogs/EventDialog';
import { useServiceContext } from '../../contexts/ServiceContext';
import { useInvoiceContext } from '../../contexts/InvoiceContext';
import { useEffect } from 'react';
import { useEventContext } from '../../contexts/EventContext';
import { useAuthContext } from '../../contexts/AuthContext';

export const Details = ({
    id,
    attachments,
    firstName,
    lastName,
    company,
    email,
    phoneNumber,
    bedConfig,
    summary,
    starts,
    time,
    location,
    team,
    status,
    quoteItems,
    quoteServiceName,
    service,
    item,
    description,
    manHours,
    hourlyRate,
    totalCharge,
    cleanersRate,
    cleanersPay,
    invoicing,
    invoicingPlatform,
    items: itemList,
    platform,
    paperwork,
    brand,
    make,
    plates,
    title,
    expiryDate,
    created,
    updated,
    createdAt,
    updatedAt,
    cleanings,
    quotes,
    client,
    worker,
    serviceData,
    serviceItems,
    paper,
    vehicle,
    invoice,
}) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const { open, scroll, handleClickOpen, handleClose, descriptionElementRef } = useDialog()

    const { isAdmin } = useAuthContext();
    const { currentEvent, getEventById } = useEventContext();

    useEffect(() => {
        if (cleanings && open && !isAdmin) {
            getEventById(id)
        }
        // eslint-disable-next-line
    }, [open])

    // Getting the related Paperworks
    const { paperworks } = usePaperworkContext();
    const paperworkList = getPaperworks(paperwork, paperworks);

    // Getting the related Service Items
    const { items } = useItemContext();
    const itemsRelated = getServiceItems(itemList, items);
    // const itemString = items?.filter(item => itemsList?.includes(item._id))?.map(item => item.item).join(', ')

    // Getting the related Event Service
    const { services } = useServiceContext();
    const serviceName = services?.filter(serv => serv._id === service).map(serv => serv.service)

    // Get Invoicing Platform
    const { invoices } = useInvoiceContext();
    const invoicePlatform = getInvoicingDetails(invoicingPlatform, invoices)


    const itemData = isAdmin ? attachments?.map(attachment => ({ img: `https://drive.google.com/uc?id=${attachment.fileId}`, title: attachment.title, id: attachment.fileId, iconUrl: attachment.iconUrl, description: attachment.description }))
        : currentEvent?.attachments?.map(attachment => ({ img: `https://drive.google.com/uc?id=${attachment.fileId}`, title: attachment.title, description: attachment.description }))

    return (
        <div>
            {/* <Button onClick={handleClickOpen('paper')}><PreviewIcon /></Button> */}
            {/* <Button ><PreviewIcon onClick={handleClickOpen('body')} /></Button> - use to be like This */}
            <PreviewIcon onClick={handleClickOpen('body')} />
            <Dialog
                open={open}
                onClose={handleClose}
                scroll={scroll}
                maxWidth='lg'
                fullWidth
                fullScreen={fullScreen}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">Details</DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {cleanings && <EventDialog summary={summary} location={location} starts={starts} time={time} client={client} serviceName={serviceName} itemsRelated={itemsRelated} description={description} manHours={Number(manHours)?.toFixed(2)} hourlyRate={Number(hourlyRate)?.toFixed(2)} totalCharge={Number(totalCharge)?.toFixed(2)} cleanersPay={Number(cleanersPay)?.toFixed(2)} cleanersRate={Number(cleanersRate)?.toFixed(2)} team={team} invoicing={invoicing} status={status} updatedAt={updated} createdAt={created} />}
                        {quotes && <QuoteDialog location={location} starts={starts} time={time} quoteServiceName={quoteServiceName} quoteItems={quoteItems} itemsRelated={itemsRelated} description={description} manHours={Number(manHours)?.toFixed(2)} hourlyRate={Number(hourlyRate)?.toFixed(2)} totalCharge={Number(totalCharge)?.toFixed(2)} updatedAt={updatedAt} createdAt={createdAt} />}
                        {client && !quotes && !cleanings && <ClientDialog firstName={firstName} lastName={lastName} company={company} email={email} phoneNumber={phoneNumber} bedConfig={bedConfig} updatedAt={updatedAt} createdAt={createdAt} />}
                        {worker && <WorkerDialog firstName={firstName} lastName={lastName} email={email} phoneNumber={phoneNumber} paperwork={paperworkList} updatedAt={updatedAt} createdAt={createdAt} />}
                        {serviceData && <ServiceDialog service={service} description={description} manHours={Number(manHours)?.toFixed(2)} hourlyRate={Number(hourlyRate)?.toFixed(2)} totalCharge={Number(totalCharge)?.toFixed(2)} cleanersRate={Number(cleanersRate)?.toFixed(2)} cleanersPay={Number(cleanersPay)?.toFixed(2)} invoicing={invoicePlatform} items={itemsRelated} updatedAt={updatedAt} createdAt={createdAt} />}
                        {serviceItems && !cleanings && <ItemDialog item={item} description={description} manHours={Number(manHours)?.toFixed(2)} hourlyRate={Number(hourlyRate)?.toFixed(2)} totalCharge={Number(totalCharge)?.toFixed(2)} cleanersRate={Number(cleanersRate)?.toFixed(2)} cleanersPay={Number(cleanersPay)?.toFixed(2)} updatedAt={updatedAt} createdAt={createdAt} />}
                        {paper && <PaperworkDialog title={title} description={description} expiryDate={expiryDate} updatedAt={updatedAt} createdAt={createdAt} />}
                        {vehicle && <VehicleDialog brand={brand} make={make} plates={plates} paperwork={paperworkList} updatedAt={updatedAt} createdAt={createdAt} />}
                        {invoice && <InvoiceDialog platform={platform} updatedAt={updatedAt} createdAt={createdAt} />}
                    </DialogContentText>

                    {itemData?.length > 0 &&
                        <ImageList sx={{ width: '100%', height: '100%' }}
                            cols={1}
                        // rowHeight={'auto'}
                        >
                            {cleanings && itemData.map((item) => (
                                <ImageListItem key={item.img}>
                                    <img
                                        src={item.img}
                                        alt={item.title}
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}

                            {paperworks && itemData.map((item) => (
                                <ImageListItem key={item.img}>
                                    <Link href={`https://drive.google.com/file/d/${item.id}/view?usp=drive_web`}>
                                        <img
                                            src={item.iconUrl}
                                            alt={item.title}
                                            loading="lazy"
                                        />
                                        {item.iconUrl && `${item.title} - ${item.description}`}
                                    </Link>
                                </ImageListItem>
                            ))}
                        </ImageList>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}
