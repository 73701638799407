import { useNavigate } from 'react-router-dom';

/**
 * Custom React hook for handling navigation when clicking on links.
 *
 * This hook returns a function that can be used as an event handler for links
 * to prevent their default behavior and navigate to the specified URL.
 *
 * @returns {function} A function that takes an event object and handles navigation.
 * @example
 * // Import the hook
 * import { useLink } from './useLink';
 *
 * // Use the hook to create a navigation event handler
 * const handleLinkClick = useLink();
 *
 * // In your component's JSX:
 * <a href="/quotes" onClick={handleLinkClick}>Go to Quotes</a>
 * <Link href='/events' onClick={(e) => navigate(e, '/events', toggleDrawer)} sx={{ textDecoration: 'none' }}>
 */
export const useLink = () => {
    const navigate = useNavigate();

    /**
     * Handles a link click event by preventing the default behavior and navigating to the specified URL.
     * Also can execute Callbacks conditionally when such is provided
     *
     * @param {Event} event - The click event object.
     * @param {Endpoint} endpoint - The path string.
     * @param {Callback} callback - The callback function.
     */
    return function (event, endpoint, callback) {
        event.preventDefault();
        const targetUrl = event?.target?.attributes[1]?.nodeValue;
        navigate(endpoint ? endpoint : targetUrl);
        if (callback) {
            callback();
        }
    };
}