export const ItemDialog = ({ item, description, manHours, hourlyRate, totalCharge, cleanersRate, cleanersPay, updatedAt, createdAt }) => {
    return (
        <>
        <p><strong>{item}</strong></p>
        <p>{description}</p>
        <hr />
        <p>Man-Hours: <strong>{manHours}</strong></p>
        <p>Hourly Rate: <strong>£{hourlyRate}</strong></p>
        <p>Total Charge: <strong>£{totalCharge}</strong></p>
        <hr />
        <p>Cleaners Rate: <strong>£{cleanersRate}</strong></p>
        <p>Cleaners Pay: <strong>£{cleanersPay}</strong></p>
        <hr />
        <p>Last Modified: <strong>{(new Date(updatedAt)).toDateString()}</strong></p>
        <p>Created At: <strong>{(new Date(createdAt)).toDateString()}</strong></p>
    </>
    );
};