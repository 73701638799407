import { useEffect, useState } from 'react';
import EventIcon from '@mui/icons-material/Event';

import { StaticDateTimePicker } from '@mui/x-date-pickers'
import {
    Autocomplete,
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
    Checkbox,
    FormControlLabel,
} from '@mui/material'

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';

import { currentDateTime, changeMonth } from '../../utils/dateUtils.js';
import { useClientContext } from "../../contexts/ClientContext";
import { useWorkerContext } from '../../contexts/WorkerContext';
import { useEventContext } from '../../contexts/EventContext';
import { useServiceContext } from '../../contexts/ServiceContext';
import { useItemContext } from '../../contexts/ItemContext';
import { useInvoiceContext } from '../../contexts/InvoiceContext';

import { getInvoicingPlatform } from '../../utils/textUtils';
import { PriceBuilder } from '../PriceBuilder/PriceBuilders';
import { getCleanersPay, getCleanersRate, getHourlyRate, getManHours, getTotalCharge } from '../../utils/reduceUtils';
import { Recurrence } from '../Events/Recurrence/Recurrence';

export const Event = () => {
    const { eventAlertMessage, setEventAlertMessage, onCreateEventSubmit } = useEventContext();
    const { services } = useServiceContext();
    const { items } = useItemContext();
    const { clients } = useClientContext();
    const { workers } = useWorkerContext();
    const { invoices } = useInvoiceContext();
    const [inputValue, setInputValue] = useState('');
    const [invoiceValue, setInvoiceValue] = useState('');
    const [availableItems, setAvailableItems] = useState([]);
    const [selectedClient, setSelectedClient] = useState({});
    const now = currentDateTime();
    const max = changeMonth(new Date(now), 6);

    const [formErrors, setFormErrors] = useState({
        summary: '',
        location: '',
        recurrence: '',
        days: '',
        interval: '',
        description: '',
        start: '',
        manHours: '',
        kings: '',
        twins: '',
        singles: '',
        doubles: '',
        client: '',
        bedConfig: '',
        service: '',
        hourlyRate: '',
        totalCharge: '',
        cleanersRate: '',
        cleanersPay: '',
        invoicingPlatform: '',
        team: '',
        items: '',
    });
    const { values, changeHandler, onSubmit, changeValues } = useForm({
        summary: '',
        location: '',
        recurrence: false,
        days: [],
        interval: '',
        description: '',
        start: '',
        manHours: '',
        kings: 0,
        twins: 0,
        singles: 0,
        doubles: 0,
        client: '',
        service: '',
        hourlyRate: '',
        totalCharge: '',
        cleanersRate: '',
        cleanersPay: '',
        invoicingPlatform: '',
        cashReceived: 0,
        team: [],
        items: [],
    }, onCreateEventSubmit);
    const { formValidate } = useError(formErrors, setFormErrors, values, now, max);

    // Ensuring the Days Array is empty if the Event switches back to Regular from Recurring with Selected Days
    useEffect(() => {
        changeValues({ ...values, days: [], interval: null })
        // eslint-disable-next-line
    }, [values.recurrence])

    useEffect(() => {
        changeValues({
            ...values,
            items: [],
            manHours: values.service?.manHours,
            toatlPrice: values.service?.totalCharge,
            description: values.service?.description,
            hourlyRate: values.service?.hourlyRate,
            totalCharge: values.service?.totalCharge,
            cleanersRate: values.service?.cleanersRate,
            cleanersPay: values.service?.cleanersPay / (Number(values.team?.length) || 1),
            invoicingPlatform: getInvoicingPlatform(values?.service?.invoicingPlatform, invoices)[0]
        })
        setAvailableItems(items?.filter(item => values.service?.items?.includes(item._id)))
        // eslint-disable-next-line
    }, [values.service, values.team])

    useEffect(() => {
        changeValues({
            ...values,
            manHours: getManHours(values.items, values.service),
            totalCharge: getTotalCharge(values.items, values.service),
            hourlyRate: getHourlyRate(values.items, values.service),
            cleanersRate: getCleanersRate(values.items, values.service, values.manHours),
            cleanersPay: getCleanersPay(values.items, values.service, values.team),
        })
        // eslint-disable-next-line
    }, [values.items, values.team])

    useEffect(() => {
        changeValues({
            ...values,
            totalCharge: values.hourlyRate * values.manHours,
            cleanersPay: values.cleanersRate * values.manHours / (Number(values.team?.length) || 1)
        })
        // eslint-disable-next-line
    }, [values.manHours, values.hourlyRate, values.cleanersRate])

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <EventIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Create a Cleaning
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        // onBlur={formValidate}
                        // error={formErrors.summary}
                        // helperText={formErrors.summary}
                        value={values.summary}
                        margin="normal"
                        required
                        fullWidth
                        id="summary"
                        label="Summary"
                        name="summary"
                        autoComplete="summary"
                        autoFocus
                    />
                    <TextField
                        onChange={changeHandler}
                        value={values.location}
                        // onBlur={formValidate}
                        // error={formErrors.location}
                        // helperText={formErrors.location}
                        margin="normal"
                        required
                        fullWidth
                        id="location"
                        label="Location"
                        name="location"
                        autoComplete="location"
                    />

                    <FormControlLabel
                        control={
                            <Checkbox
                                value={values.recurrence}
                                checked={values.recurrence ? true : false}
                                onChange={() => changeValues(() => ({ ...values, recurrence: !values.recurrence }))}
                                name='recurrence'
                                color="secondary"
                                id='recurrence'
                            />
                        }
                        label="Recurring Event"
                    />

                    {values.recurrence &&
                        <Recurrence values={values} changeValues={changeValues} />
                    }



                    <StaticDateTimePicker
                        // maxDate={new Date('future')}
                        // minDate={new Date('nearFuture')}
                        onChange={(newValue, changeHandler) => {
                            values.start = newValue?.$d
                        }}
                        value={values.start}
                        minutesStep={5}
                        slots={{
                            actionBar: 'div',
                        }}
                        disablePast={true}
                    />

                    <Autocomplete
                        // onChange={changeHandler}
                        value={values.client}
                        onChange={(event, newValue) => {
                            values.client = newValue
                        }}
                        inputValue={selectedClient}
                        onInputChange={(event, newInputValue) => {
                            setSelectedClient(newInputValue);
                        }}
                        fullWidth
                        required
                        disablePortal
                        id="client"
                        label="Client"
                        name="client"
                        options={clients}
                        renderInput={(params) => <TextField
                            margin='normal'
                            {...params}
                            label="Client *"
                        />}
                    />

                    {values?.client?.bedConfig &&
                        <TextField
                            onChange={changeHandler}
                            value={values.bedConfig}
                            margin="normal"
                            required
                            fullWidth
                            id="bedConfig"
                            label="Bed Configuration"
                            name="bedConfig"
                            autoComplete="bedConfig"
                            autoFocus
                        />
                    }

                    <Autocomplete
                        multiple
                        value={values.team}
                        onChange={(event, newValue) => {
                            changeValues({ ...values, team: newValue })
                        }}
                        options={workers}
                        // id="service-items"
                        // getOptionLabel={(option) => option.title}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                margin='normal'
                                {...params}
                                label="Team"
                                placeholder="+ Add"
                            />
                        )}
                    />

                    <Autocomplete
                        // onChange={changeHandler}
                        value={values.service}
                        onChange={(event, newValue) => {
                            values.service = newValue
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                        }}
                        fullWidth
                        required
                        disablePortal
                        id="service"
                        label="Service"
                        name="service"
                        autoComplete="service"
                        options={services}
                        renderInput={(params) => <TextField
                            margin='normal'
                            {...params}
                            label="Service *"
                        />}
                    />

                    {availableItems.length > 0 &&
                        <Autocomplete
                            multiple
                            value={values.items}
                            onChange={(event, newValue) => {
                                changeValues({ ...values, items: newValue })
                            }}
                            options={availableItems}
                            // id="service-items"
                            // getOptionLabel={(option) => option.title}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    margin='normal'
                                    {...params}
                                    label="Service Items"
                                    placeholder="+ Add"
                                />
                            )}
                        />
                    }

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.description}
                        helperText={formErrors.description}
                        value={values.description}
                        margin="normal"
                        // required
                        fullWidth
                        multiline
                        rows={4}
                        id="description"
                        label="Description"
                        name="description"
                        autoComplete="description"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.manHours}
                        helperText={formErrors.manHours}
                        value={values.manHours}
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        id="manHours"
                        label="Man-Hours"
                        name="manHours"
                        autoComplete="manHours"
                    />

                    {/* {availableItems.length > 0 &&
                        <Autocomplete
                            multiple
                            value={values.items}
                            onChange={(event, newValue) => {
                                changeValues({ ...values, items: newValue })
                            }}
                            options={availableItems}
                            // id="service-items"
                            // getOptionLabel={(option) => option.title}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField
                                    margin='normal'
                                    {...params}
                                    label="Service Items"
                                    placeholder="+ Add"
                                />
                            )}
                        />
                    } */}

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.hourlyRate}
                        helperText={formErrors.hourlyRate}
                        value={values.hourlyRate}
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        id="hourlyRate"
                        label="Hourly Rate"
                        name="hourlyRate"
                        autoComplete="hourlyRate"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.totalCharge}
                        helperText={formErrors.totalCharge}
                        value={values.totalCharge}
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        id="totalCharge"
                        label="Total Charge"
                        name="totalCharge"
                        autoComplete="totalCharge"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.cleanersRate}
                        helperText={formErrors.cleanersRate}
                        value={values.cleanersRate}
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        id="cleanersRate"
                        label="Cleaners Rate"
                        name="cleanersRate"
                        autoComplete="cleanersRate"
                    />

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        error={formErrors.cleanersPay}
                        helperText={formErrors.cleanersPay}
                        value={values.cleanersPay}
                        margin="normal"
                        required
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        id="cleanersPay"
                        label="Cleaners Pay"
                        name="cleanersPay"
                        autoComplete="cleanersPay"
                    />

                    <Autocomplete
                        // onChange={changeHandler}
                        value={values.invoicingPlatform}
                        onChange={(event, newValue) => {
                            values.invoicingPlatform = newValue
                        }}
                        inputValue={invoiceValue}
                        onInputChange={(event, newInputValue) => {
                            // changeValues({...values, invoicingPlatform: newInputValue});
                            setInvoiceValue(newInputValue)
                        }}
                        fullWidth
                        required
                        disablePortal
                        id="invoicingPlatform"
                        label="Invoicing Platform"
                        name="invoicingPlatform"
                        options={invoices}
                        renderInput={(params) => <TextField
                            margin='normal'
                            {...params}
                            label="Invoicing *"
                        />}
                    />


                    <PriceBuilder values={values} changeValues={changeValues} />

                    {eventAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setEventAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {eventAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                    {/* <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid> */}
                </Box>
            </Box>
        </Container >
    );
};