import { requestFactory } from "./requester"

const baseUrl = process.env.REACT_APP_ENV_HERE === "production"
    ? 'https://titan-cleaners-be.onrender.com/api/quotes'
    : 'http://localhost:3005/api/quotes'

export const quoteServiceFactory = (token) => {
    const request = requestFactory();

    const getAll = async () => {
        const result = await request.get(`${baseUrl}`);

        return result.quotes;
    };

    const getOne = async (quoteId) => {
        const result = await request.get(`${baseUrl}/${quoteId}`);

        return result.quote;
    };

    const create = async (quoteData) => {
        const result = await request.post(baseUrl, quoteData);

        return result.quote;
    };

    const edit = (quoteId, data) => request.put(`${baseUrl}/${quoteId}`, data);

    const deleteQuote = (quoteId) => {
        request.delete(`${baseUrl}/${quoteId}`)
    }

    return {
        getAll,
        getOne,
        create,
        edit,
        delete: deleteQuote,
    };
};
