import { useClientContext } from "../../contexts/ClientContext";
import { useInvoiceContext } from "../../contexts/InvoiceContext";
import { useItemContext } from '../../contexts/ItemContext';
import { useServiceContext } from "../../contexts/ServiceContext";
import { useQuoteContext } from "../../contexts/QuoteContext";
import { useWorkerContext } from "../../contexts/WorkerContext";

import { useAuthContext } from "../../contexts/AuthContext";

import { useLink } from "../../hooks/useLink";

import { getClientDetails, getInvoicingDetails } from "../../utils/textUtils";
import { formatDate } from "../../utils/dateUtils";
import { getTeam } from "../../utils/eventUtils";
import { days } from "../../utils/mappers";

import { Typography, Button, IconButton, Link } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import LinearProgress from '@mui/material/LinearProgress';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

import { Details } from "../Details/Details";


export const QuoteTable = () => {
    const { isAdmin } = useAuthContext();
    
    const { quotes } = useQuoteContext();
    const { clients } = useClientContext();
    const { workers } = useWorkerContext();
    const { services } = useServiceContext();
    const { invoices } = useInvoiceContext();
    const { items } = useItemContext();

    const navigate = useLink();


    quotes?.map(function (quote) {
        quote.id = quote._id
        quote.starts = quote.start.dateTime ? formatDate(quote.start.dateTime) : formatDate(quote.start.date)
        quote.day = quote.start.dateTime ? days[new Date(quote.start.dateTime).getDay()] : days[new Date(quote.start.date).getDay()]
        quote.time = quote.start.dateTime ? `${new Date(quote.start.dateTime).getHours()} ${String(new Date(quote.start.dateTime).getMinutes()).padStart(2, '0')}` : ''
        quote.client = getClientDetails(quote.extendedProperties?.private?.client, clients)
        // quote.duration = getDuration(quote.start?.dateTime, quote.end?.dateTime)
        quote.team = getTeam(quote?.team, workers)
        quote.quoteService = services?.filter(service => service._id === quote.service)
        quote.quoteItems = items?.filter(item => quote?.items?.includes(item._id))?.map(item => item.item)?.join(', ')
        // quote.quoteItems = quote?.items?.map(item => item.item)?.join(', ')
        quote.quoteServiceName = quote.quoteService[0]?.service
        // quote.manHours = quote.quoteService[0].manHours
        quote.manHours = items?.filter(item => quote?.items?.includes(item._id))
            ?.map(item => item?.manHours)
            ?.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                Number(quote.quoteService[0]?.manHours)
            ).toFixed(2)


        quote.totalCharge = items?.filter(item => quote?.items?.includes(item._id))
            ?.map(item => item.totalCharge)
            ?.reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                Number(quote.quoteService[0]?.totalCharge)
            )

        quote.hourlyRate = (quote.totalCharge / quote.manHours).toFixed(2)
        quote.invoicing = getInvoicingDetails(quote.quoteService[0]?.invoicingPlatform, invoices)
        quote.createdAt = new Date(quote.createdAt).toLocaleString()
        return quote
    })

    const columns = [
        {
            field: 'actions', headerName: 'Actions', width: 120,
            renderCell: (params) =>
                <>
                    <Link title='Delete' href={`/quotes/${params.id}/delete`} onClick={(e) => navigate(e, `/quotes/${params.id}/delete`)} sx={{ color: "text.secondary" }}><DeleteIcon /></Link>
                    <Link title='Edit' href={`/quotes/${params.id}/edit`} onClick={(e) => navigate(e, `/quotes/${params.id}/edit`)} sx={{ color: "text.secondary" }}><EditIcon /></Link>
                    <Link title='Approve' href={`/quotes/${params.id}/approve`} onClick={(e) => navigate(e, `/quotes/${params.id}/approve`)} sx={{ color: "text.secondary" }}><AssignmentTurnedInIcon /></Link>
                    <IconButton aria-label="comment">
                        <Details key={params.row.id} {...params.row} quotes />
                    </IconButton>
                </>
        },
        { field: 'summary', headerName: 'Summary', width: 150 },
        { field: 'starts', headerName: 'Date', width: 150 },
        { field: 'day', headerName: 'Day', width: 150 },
        { field: 'time', headerName: 'Time', width: 150 },
        { field: 'location', headerName: 'Address', width: 150 },
        { field: 'client', headerName: 'Client', width: 150 },
        { field: 'description', headerName: 'Job Details', width: 150 },
        { field: 'manHours', headerName: 'Man-Hours', width: 150 },
        { field: 'hourlyRate', headerName: 'Hourly Rate', width: 150 },
        { field: 'totalCharge', headerName: 'Total Charge', width: 150 },
        { field: 'quoteServiceName', headerName: 'Service', width: 150 },
        { field: 'quoteItems', headerName: 'Items', width: 150 },
        { field: 'invoicing', headerName: 'Invoicing', width: 150 },
        { field: 'createdAt', headerName: 'Created At', width: 150 },
    ];

    return (
        <>
            <Typography
                variant="h5"
                component='h5'
                sx={{
                    textAlign: 'center', mt: 3, mb: 3,
                }}
            >
                Quotes List
            </Typography>
            {isAdmin &&
                <Button size="small" onClick={(e) => navigate(e, '/request-cleaning')}>
                    Add a Quote
                </Button>
            }
            <DataGrid
                columns={columns}
                rows={quotes}
                slots={{
                    toolbar: GridToolbar,
                    loadingOverlay: LinearProgress,
                }}
                slotProps={{
                    columnMenu: { background: 'red', counter: quotes.length },
                    toolbar: {
                        showQuickFilter: true,
                        quickFilterProps: { debounceMs: 500 },
                    },
                }}
            />
        </>
    );
};