import MuiAppBar from '@mui/material/AppBar';
import { styled } from '@mui/material/styles';

export const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
//   })(({ theme, open }) => ({
  })(({ theme}) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // Uncommenting this is going to move the Toggle MenuIcon left and right only in the Home component
    // ...(open && {
    // //   marginLeft: 240,
    //   width: '100vw',
    //   transition: theme.transitions.create(['width', 'margin'], {
    //     easing: theme.transitions.easing.sharp,
    //     duration: theme.transitions.duration.enteringScreen,
    //   }),
    // }),
  }));