import { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { clientServiceFactory } from '../services/clientService';

export const ClientContext = createContext();

export const ClientProvider = ({
    children,
}) => {
    const navigate = useNavigate();
    const clientService = clientServiceFactory();
    const [clients, setClients] = useState([]);
    const [clientAlertMessage, setClientAlertMessage] = useState('');

    const getAllClients = async () => {
        const allClients = await clientService.getAll();
        allClients?.map(function (client) {
            client.id = client._id
            client.label = `${client.firstName} ${client.lastName} - ${client.company}, ${client.phoneNumber}, ${client.email}`
            client.createdAt = new Date(client.createdAt).toLocaleString()
            return client
        })
        setClients(allClients);
    };

    useEffect(() => {
        getAllClients()
        // eslint-disable-next-line
    }, []);

    const onCreateClientSubmit = async (data) => {
        try {
            const createdClient = await clientService.create(data);
            createdClient.id = createdClient?._id

            setClients([...clients, createdClient])

            navigate('/clients');
        } catch (error) {
            return setClientAlertMessage(error.message)
        }
    };

    const onEditClientSubmit = async (values) => {
        try {
            const result = await clientService.edit(values._id, values);

            setClients(state => state.map(x => x._id === values._id ? { ...result?.client, id: result?.client?._id } : x));

            navigate(`/clients/`);
        } catch (error) {
            setClientAlertMessage(error.message);
        }
    };

    const deleteClient = async (clientObject) => {
        await clientService.delete(clientObject._id)

        setClients(state => state.filter(client => client._id !== clientObject._id));

        navigate(`/clients/`);
    };

    const context = {
        clients,
        clientAlertMessage,
        setClientAlertMessage,
        onCreateClientSubmit,
        onEditClientSubmit,
        deleteClient,

    }

    return (
        <ClientContext.Provider value={context}>
            {children}
        </ClientContext.Provider>
    );
};

export const useClientContext = () => {
    const context = useContext(ClientContext);

    return context;
}
