import { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useDrivePicker from 'react-google-drive-picker'

import { gapi } from "gapi-script";

import { useAuthContext } from './AuthContext';
import { useQuoteContext } from './QuoteContext';

import { quoteServiceFactory } from '../services/quoteService';

import { addHours, formatDateToCustomISO } from '../utils/dateUtils';
import { filterTeam } from '../utils/teamUtils';
import { getRecurringDays } from '../utils/eventUtils';
import { usePaperworkContext } from './PaperworkContext';
import { getFirstNameFromEmail } from '../utils/textUtils';

export const EventContext = createContext();

export const EventProvider = ({
    children,
}) => {
    const navigate = useNavigate();
    const { session, isAdmin } = useAuthContext();
    const [openPicker] = useDrivePicker();

    const { setQuotes } = useQuoteContext();
    const { getPaperwork, onEditPaperworkSubmit } = usePaperworkContext();

    const [events, setEvents] = useState([]);
    const quoteService = quoteServiceFactory();
    const [eventAlertMessage, setEventAlertMessage] = useState('');
    const [currentEvent, setCurrentEvent] = useState([]);
    const [editedEvents, setEditedEvents] = useState([]);

    const main = process.env.REACT_APP_CALENDAR_ID;
    const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
    const clientId = process.env.REACT_APP_CLIENT_ID;

    const getEvents = (period, anonymous) => {
        function initiate() {
            gapi.client
                .init({
                    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
                    // apiKey: apiKey,
                })
                // .then(() => {
                //     gapi.auth2.init({
                //         client_id: clientID,
                //         scope: scope + ' ' + 'https://www.googleapis.com/auth/drive.file',
                //     })
                // })
                .then(function () {
                    try {
                        return gapi.client.request({
                            path: `https://www.googleapis.com/calendar/v3/calendars/${isAdmin || anonymous ? main : session?.user?.email}/events`,
                            'method': 'GET',
                            'params': {
                                'key': apiKey,
                                'singleEvents': true,
                                'orderBy': 'startTime',
                                'timeMin': period.timeMin?.toISOString().replace(".000Z", "+03:00"),
                                'timeMax': period.timeMax?.toISOString().replace(".000Z", "+03:00"),
                                'maxResults': 534,
                                // 'pageToken': pageToken,
                                // 'syncToken': "CLjaruWmpP8CEAAYASC-sab_AQ=="
                            },
                        });
                    } catch (error) {
                        console.log(error)
                    }
                })

                .then(
                    (response) => {
                        let incomingEvents = response?.result?.items || [];
                        // Make sure nextSyncToken is present ( drop singleEvents, orderBy, timeMin & timeMax headers )
                        // if (response.result.nextPageToken) {
                        //   setPageToken(response.result.nextPageToken)
                        //   return setEvents([...events, ...incomingEvents]);
                        // } else if (response.result.nextSyncToken && events?.length > 0) {
                        //   return setEvents([...events, ...incomingEvents])
                        // }
                        if (anonymous) {
                            return setEvents(incomingEvents?.map(event => ({ ...event, extendedProperties: {} })))
                        }
                        return setEvents(incomingEvents)
                    },
                    function (err) {
                        return [false, err];
                    }
                );
        }

        gapi.load("client", initiate);


    };

    const onCreateEventSubmit = async (data) => {
        try {
            await createEvent(data);

            if (data.quoteId) {
                quoteService.delete(data.quoteId)

                setQuotes(state => state.filter(quote => quote._id !== data.quoteId));
            }

            navigate('/events');
        } catch (error) {
            setEventAlertMessage(error.message);
        }
    };

    const deleteEvent = async (data) => {
        try {
            deleteEventById(data, main);

            data?.team?.map(member => deleteEventById(data, member.email))

            navigate('/events');
        } catch (error) {
            setEventAlertMessage(error.message);
        }
    };

    // const onEditEventSubmit = async (data) => {
    //     try {
    //         editEventById(data, main);

    //         // Deleting the Event from the Calendars of Removed Service Providers
    //         filterTeam(data?.initialTeam, data?.team)?.
    //             map(member => deleteEventById({ id: data?.id }, member.email))

    //         // Inserting the Event in the Calendars of Added Service Providers
    //         filterTeam(data?.team, data?.initialTeam)?.
    //             map(member => insertEvent(data, member.email))

    //         data?.team?.map(member => editEventById(data, member.email))

    //         navigate('/events');
    //     } catch (error) {
    //         setEventAlertMessage(error.message);
    //     }
    // };

    const onEditEventSubmit = async (data) => {
        try {
            if (filterTeam(data?.initialTeam, data?.team)?.length > 0 || filterTeam(data?.team, data?.initialTeam)?.length > 0) {

                // Deleting the Event from the Main Calendar
                deleteEventById({ id: data?.id }, main);

                data?.initialTeam?.map(member => deleteEventById({ id: data?.id }, member.email))

                // // Deleting the Event from the Calendars of Removed Service Providers
                // filterTeam(data?.initialTeam, data?.team)?.
                //     map(member => deleteEventById({ id: data?.id }, member.email))

                // // Deleting the Event in the Calendars of Added Service Providers
                // filterTeam(data?.team, data?.initialTeam)?.
                //     map(member => deleteEventById({ id: data?.id }, member.email))

                await createEvent(data)

            } else {
                editEventById(data, main);

                //         // Deleting the Event from the Calendars of Removed Service Providers
                //         filterTeam(data?.initialTeam, data?.team)?.
                //             map(member => deleteEventById({ id: data?.id }, member.email))

                //         // Inserting the Event in the Calendars of Added Service Providers
                //         filterTeam(data?.team, data?.initialTeam)?.
                //             map(member => insertEvent(data, member.email))

                data?.team?.map(member => editEventById(data, member.email))
            }

            navigate('/events');
        } catch (error) {
            setEventAlertMessage(error.message);
        }
    };


    // "gapi.auth2.authorize cannot be called after GoogleAuth has been initialized 
    // (i.e. with a call to gapi.auth2.init, or gapi.client.init when given a 'clientId' and a 'scope' parameters)."
    function populateFolder(folderName, parentFolder, id, type) {
        function initiate() {
            gapi.client
                .init({
                    discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'],
                })
                .then(() => {
                    gapi.client.setToken({
                        access_token: session?.provider_token,
                    })
                })
                .then(function () {
                    try {
                        return gapi.client.drive.files.create({
                            resource: {
                                name: folderName,
                                mimeType: 'application/vnd.google-apps.folder',
                                parents: [parentFolder],
                            },
                            fields: 'id',
                            supportsAllDrives: true,
                        })
                    } catch (err) {
                        return alert('Failed to upload your images')
                    }
                })
                .then(
                    (response) => {
                        return response?.result?.id
                    },
                    function (err) {
                        return [false, err];
                    }
                )
                .then((response) =>
                    openPicker({
                        clientId: clientId,
                        developerKey: apiKey,
                        viewId: "DOCS",
                        // appId: 'titan-cleaners',
                        // setParentFolder: '1uBlUhAmiptleU0iNZqWca4QazJjQwOWX',
                        token: session?.provider_token,
                        showUploadView: true,
                        showUploadFolders: true,
                        supportDrives: true,
                        multiselect: true,
                        customScopes: ['https://www.googleapis.com/auth/drive.file'],
                        // setOrigin: 'https://docs.google.com/',
                        setParentFolder: response ? response : '15qAPZJwEjbR4nerQyutNelZHcVuWeBHh',
                        // customViews: customViewsArray, // custom view
                        callbackFunction: async (data) => {
                            if (data.action === 'cancel') {
                                deleteFolder(response)
                            }
                            if (data.action === 'picked') {
                                // Returns Admin (due to undefined) if left-part of email doesn't have "."
                                const uploadedBy = getFirstNameFromEmail(session?.user?.email)
                                const docs = data?.docs?.map(file => ({ ...file, description: uploadedBy }))
                                if (type === 'images') {
                                    attachImages(docs, id)
                                }
                                if (type === 'paperworks') {
                                    const paperwork = await getPaperwork(id)
                                    // Now saving only Id and Name of the docs as objects inside the Paperwork.attachments array
                                    // fileId: doc.id acts as download link
                                    onEditPaperworkSubmit({
                                        ...paperwork, attachments: [...paperwork.attachments, ...docs?.map(doc => ({ fileId: doc.id, title: doc.name, iconUrl: doc.iconUrl, description: doc.description }))]
                                    })
                                }
                            }
                        },
                    })
                )
                .catch((err) => {
                    alert(err.message)
                })
        }

        gapi.load("client", initiate);
    }

    function deleteFolder(folderId) {
        function initiate() {
            gapi.client
                .init({
                    apiKey: apiKey,
                })
                .then(function () {
                    return gapi.client.request({
                        path: `https://www.googleapis.com/drive/v3/files/${folderId}`,
                        'method': 'DELETE',
                        'params': {
                            // 'key': apiKey,
                            supportsAllDrives: true,
                        },
                    });
                })

                .then(
                    (response) => {
                        console.log(response)
                    },
                    function (err) {
                        return [false, err];
                    }
                );
        }

        gapi.load("client", initiate);
    }

    async function createEvent({
        summary,
        location,
        recurrence,
        fromRecurring,
        days,
        interval,
        description,
        start,
        manHours,
        client,
        service,
        // hourlyRate,
        totalCharge,
        cleanersRate,
        cleanersPay,
        invoicingPlatform,
        cashReceived,
        items,
        team,
        bedConfig,
        quoteId
    }) {

        const event = {
            'summary': summary,
            'location': location,
            // 'description': bedConfig(kings,
            //     twins, doubles, singles) + description,
            // 'description': (bedConfig || '') + '\n' + description,
            'description': description,
            'start': {
                'dateTime': new Date(start).toISOString(),
                'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            'end': {
                'dateTime': addHours(new Date(start), (manHours / (team?.length || 1))).toISOString(),
                'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            "extendedProperties": {
                "private": {
                    'client': client._id,
                    'service': service._id,
                    'hourlyRate': totalCharge / manHours,
                    'totalCharge': totalCharge,
                    'cleanersRate': cleanersRate,
                    'cleanersPay': cleanersPay,
                    'invoicingPlatform': invoicingPlatform._id,
                    'cashReceived': cashReceived,
                    'team': team?.map(member => member._id).join(' '),
                    'items': items?.map(item => item._id).join(' '),
                    'manHours': manHours,
                },
                "shared": {
                    "bedConfig": bedConfig,
                }
            },
        }

        if (recurrence) {
            event.recurrence = [
                `RRULE:FREQ=WEEKLY;INTERVAL=${interval?.value || 1};BYDAY=${getRecurringDays(days)}`,
                // 'RRULE:FREQ=DAILY;COUNT=5',
                // "RRULE:FREQ=WEEKLY;UNTIL=20230831T170000Z",

                // 'RRULE:FREQ=WEEKLY;INTERVAL=2;BYDAY=TU'
                // 'RRULE:FREQ=WEEKLY;INTERVAL=3;BYDAY=TH'

                // "RDATE;VALUE=DATE:20230816T170000Z",
                // "EXDATE;VALUE=DATE:20230815T170000Z,20230817T170000Z",
                // 'RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=TU,TH'
            ];
        }

        // Used in the case when the Recurring Event is created by Modifying all Following Events
        if (fromRecurring) {
            event.recurrence = fromRecurring;
        }

        const newEvent = await fetch(`https://www.googleapis.com/calendar/v3/calendars/${main}/events`, {
            method: "POST",
            headers: {
                'Authorization': 'Bearer ' + session.provider_token
            },
            body: JSON.stringify(event)
        }).then((data) => {
            console.log({ data });
            return data.json();
        }).then((data) => {
            // Write to back-end
            event.id = data.id
            return data;
        }).catch(error => {
            return alert(error)
        })

        team?.map(async member => {
            await fetch(`https://www.googleapis.com/calendar/v3/calendars/${member.email}/events`, {
                method: "POST",
                headers: {
                    'Authorization': 'Bearer ' + session.provider_token
                },
                body: JSON.stringify(event)
            }).then((data) => {
                console.log({ data });
                return data.json();
            }).then((data) => {
                // Write to back-end
                return data;
            }).catch(error => {
                return alert(error)
            })
        })

        return newEvent;
    }

    const getEventById = (eventId) => {
        function initiate() {
            gapi.client
                .init({
                    apiKey: apiKey,
                })
                .then(function () {
                    return gapi.client.request({
                        path: `https://www.googleapis.com/calendar/v3/calendars/${main}/events/${eventId}`,
                        'method': 'GET',
                        'params': {
                            'key': apiKey,
                        },
                    });
                })

                .then(
                    (response) => {
                        return setCurrentEvent(response.result)
                    },
                    function (err) {
                        return [false, err];
                    }
                );
        }

        gapi.load("client", initiate);
    }

    const editEventById = async ({
        id,
        summary,
        location,
        description,
        start,
        manHours,
        client,
        service,
        // hourlyRate,
        totalCharge,
        cleanersRate,
        cleanersPay,
        invoicingPlatform,
        cashReceived,
        items,
        team,
        bedConfig,
    }, calId) => {
        const event = {
            'summary': summary,
            'location': location,
            // 'description': bedConfig(kings,
            //     twins, doubles, singles) + description,
            // 'description': (bedConfig || '') + '\n' + description,
            'description': description,
            'start': {
                'dateTime': (new Date(start)).toISOString(),
                'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            'end': {
                'dateTime': addHours((new Date(start)), (manHours / (team?.length || 1))).toISOString(),
                'timeZone': Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            "extendedProperties": {
                "private": {
                    'client': client._id,
                    'service': service._id,
                    'hourlyRate': totalCharge / manHours,
                    'totalCharge': totalCharge,
                    'cleanersRate': cleanersRate,
                    'cleanersPay': cleanersPay,
                    'invoicingPlatform': invoicingPlatform?._id,
                    'cashReceived': cashReceived,
                    'team': team?.map(member => member._id).join(' '),
                    'items': items?.map(item => item._id).join(' '),
                    'manHours': manHours,
                },
                "shared": {
                    "bedConfig": bedConfig,
                }
            },
        }

        function initiate() {
            gapi.client
                .init({
                    apiKey: apiKey,
                })
                .then(function () {
                    return gapi.client.request({
                        path: `https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${id}`,
                        'method': 'PATCH',
                        'params': {
                            'key': apiKey,
                        },
                        'headers': {
                            'Authorization': 'Bearer ' + session.provider_token
                        },
                        'body': JSON.stringify(event)
                    }).then((data) => {
                        console.log(data);
                        return data?.result;
                    })
                        .catch(result => {
                            console.log(result)
                            return alert(result.result.error.message, calId)
                        })
                })
                .then(
                    (response) => {
                        return setEditedEvents(state => [response])
                    },
                    function (err) {
                        return [false, err];
                    }
                );
        }

        gapi.load("client", initiate);
    }

    const attachImages = async (data, eventId) => {
        function initiate() {
            gapi.client
                .init({
                    apiKey: apiKey,
                })
                .then(() => {
                    gapi.client.setToken({
                        access_token: session?.provider_token,
                    })
                })
                .then(function () {
                    return gapi.client.request({
                        path: `https://www.googleapis.com/calendar/v3/calendars/${main}/events/${eventId}`,
                        'method': 'GET',
                        'params': {
                            'key': apiKey,
                        },
                        'headers': {
                            'Authorization': 'Bearer ' + session?.provider_token
                        },
                    })
                        .then((data) => {
                            console.log(data);
                            return data?.result?.attachments;
                        })
                        .catch(result => {
                            console.log(result)
                            return alert(result.result.error.message, main)
                        })
                })
                .then(function (attachments) {
                    const event = {
                        attachments: [
                            ...attachments,
                            ...data?.map(file =>
                            ({
                                fileUrl: file.url,
                                title: file.description + " - " + file.name,
                                mimeType: file.mimeType,
                                iconLink: file.iconUrl,
                                fileId: file.id,
                                // That's all the possible properties supported by Event Object Attachments
                            })
                            ),
                        ]
                    }

                    return gapi.client.request({
                        path: `https://www.googleapis.com/calendar/v3/calendars/${main}/events/${eventId}`,
                        'method': 'PATCH',
                        'params': {
                            'key': apiKey,
                            "supportsAttachments": true,
                        },
                        'headers': {
                            'Authorization': 'Bearer ' + session?.provider_token
                        },
                        'body': JSON.stringify(event)
                    })
                        .then((data) => {
                            console.log(data);
                            return data?.result;
                        })
                        .catch(result => {
                            console.log(result)
                            return alert(result.result.error.message, main)
                        })
                });
            // Logic for Managint the Attachments of Events in Workers Calendars requieres Sharing to work
            // .then(
            //     (response) => {
            //         team?.map(member => {
            //             gapi.client.request({
            //                 path: `https://www.googleapis.com/calendar/v3/calendars/${member.email}/events/${eventId}`,
            //                 'method': 'PATCH',
            //                 'params': {
            //                     'key': apiKey,
            //                     "supportsAttachments": true,
            //                 },
            //                 'headers': {
            //                     'Authorization': 'Bearer ' + session?.provider_token
            //                 },
            //                 'body': JSON.stringify(event)
            //             })
            //             return setEditedEvents(state => [response])
            //         },
            //             function (err) {
            //                 return [false, err];
            //             }
            //         );
            //     })
        }

        gapi.load("client", initiate);
    }


    const deleteEventById = ({
        id,
    }, calId) => {

        function initiate() {
            gapi.client
                .init({
                    apiKey: apiKey,
                })
                .then(function () {
                    return gapi.client.request({
                        path: `https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${id}`,
                        'method': 'DELETE',
                        'params': {
                            'key': apiKey,
                        },
                        'headers': {
                            'Authorization': 'Bearer ' + session.provider_token
                        },
                    }).then((data) => {
                        console.log(data);
                        return data?.result;
                    })
                        .catch(result => {
                            console.log(result)
                            return alert(result.result.error.message, calId)
                        })
                })
                .then(
                    (response) => {
                        // using Edit Event State to refresh Events after Deletion
                        return setEditedEvents(state => [response])
                    },
                    function (err) {
                        return [false, err];
                    }
                );
        }

        gapi.load("client", initiate);
    }

    /**
     * Asks for confirmation before deleting an event from the calendar.
     * 
     * @param {Object} data - The data of the event to be deleted.
     */
    const cancelEvent = async (data) => {
        const choice = window.confirm(`Are you sure you want to cancel this recurring event - ${data.summary} ${data.starts} ${data.location}?`)
        
        if (!choice) {
            return;
        }

        try {
            // Deletes the event for the main user
            cancelEventById(data, main);

            // Deletes the event for each team member
            data?.teamMembers?.map(member => cancelEventById(data, member.email));

            // Updates the events state by filtering out the deleted event
            setEvents(events?.filter(event => event.id !== data.id));
        } catch (error) {
            // Sets an error message if an error occurs during deletion
            setEventAlertMessage(error.message);
        }
    };

    const cancelEventById = ({
        id,
    }, calId) => {
        // const sampleEvent = {
        //     status: "cancelled",
        //     kind: event.kind, // "calendar#event",
        //     id: event.id, // "instanceId",
        //     etag: event.etag, // "instanceEtag",
        //     htmlLink: event.htmlLink, // "https://www.google.com/calendar/event?eid=instanceEid",
        //     created: event.created, // "2011-05-23T22:27:01.000Z",
        //     updated: event.updated, // "2011-05-23T22:27:01.000Z",
        //     summary: event.summary, // "Recurring event",
        //     location: event.location, // "Somewhere",
        //     creator: event.creator, // {"email": "userEmail"},
        //     recurringEventId: event.recurringEventId, // "recurringEventId",
        //     originalStartTime: event.originalStartTime, // "2011-06-03T10:00:00.000-07:00",
        //     organizer: event.organizer, // {"email": "userEmail","displayName": "userDisplayName"},
        //     start: event.start, // {"dateTime": "2011-06-03T10:00:00.000-07:00","timeZone": "America/Los_Angeles"},
        //     end: event.end, // {"dateTime": "2011-06-03T10:25:00.000-07:00","timeZone": "America/Los_Angeles"},
        //     iCalUID: event.iCalUID, // "eventUID",
        //     sequence: event.sequence, // 0,
        //     totalCharge: event.totalCharge,
        //     time: event.time,
        //     team: event.team,
        //     starts: event.starts,
        //     serviceItems: event.serviceItems,
        //     manHours: event.manHours,
        //     invoicing: event.invoicing,
        //     hourlyRate: event.hourlyRate,
        //     extendedProperties: event.extendedProperties,
        //     eventType: event.eventType,
        //     duration: event.duration,
        //     day: event.day,
        //     client: event.client,
        //     cleanersRate: event.cleanersRate,
        //     cleanersPay: event.cleanersPay,
        //     cleaners: event.cleaners,
        //     attachments: event.attachments,
        // }

        function initiate() {
            gapi.client
                .init({
                    apiKey: apiKey,
                })
                .then(function () {
                    return gapi.client.request({
                        path: `https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${id}`,
                        method: 'PATCH',
                        params: {
                            key: apiKey,
                        },
                        headers: {
                            'Authorization': 'Bearer ' + session.provider_token
                        },
                        body: JSON.stringify({status: 'cancelled'})
                    }).then((data) => {
                        console.log(data);
                        return data?.result;
                    })
                        .catch(result => {
                            console.log("🚀 ~ file: EventContext.js:733 ~ result:", result)
                            return alert(result.result.error.message, calId)
                        })
                })
                .then(
                    (response) => {
                        // using Edit Event State to refresh Events after Deletion
                        return setEditedEvents(state => [response])
                    },
                    function (err) {
                        return [false, err];
                    }
                );
        }

        gapi.load("client", initiate);
    }

    const modifyAllFollowing = async (data) => {
        console.log("🚀 ~ file: EventContext.js:780 ~ modifyAllFollowing ~ data:", data)
        getEventById(data.recurringEventId)
        console.log(`${currentEvent.recurrence};UNTIL=${formatDateToCustomISO(addHours(new Date(data.start.dateTime), -1))}`)
        // ['RRULE:FREQ=WEEKLY;INTERVAL=1;BYDAY=TU,TH']
        // console.log(addHours(new Date(data.start.dateTime), -1))
        // try {
        //     // Deletes the event for the main user
        //     cancelEventById(data, main);

        //     // Deletes the event for each team member
        //     data?.teamMembers?.map(member => cancelEventById(data, member.email));

        //     // Updates the events state by filtering out the deleted event
        //     setEvents(events?.filter(event => event.id !== data.id));
        // } catch (error) {
        //     // Sets an error message if an error occurs during deletion
        //     setEventAlertMessage(error.message);
        // }
    }

    const modifyRecurringEventById = ({
        recurringEventId,
        start
    }, calId) => {

        function initiate() {
            gapi.client
                .init({
                    apiKey: apiKey,
                })
                .then(function () {
                    return gapi.client.request({
                        path: `https://www.googleapis.com/calendar/v3/calendars/${calId}/events/${recurringEventId}`,
                        method: 'PATCH',
                        params: {
                            key: apiKey,
                        },
                        headers: {
                            'Authorization': 'Bearer ' + session.provider_token
                        },
                        body: JSON.stringify({ start })
                    }).then((data) => {
                        console.log(data);
                        return data?.result;
                    })
                        .catch(result => {
                            console.log("🚀 ~ file: EventContext.js:733 ~ result:", result)
                            return alert(result.result.error.message, calId)
                        })
                })
                .then(
                    (response) => {
                        // using Edit Event State to refresh Events after Deletion
                        return setEditedEvents(state => [response])
                    },
                    function (err) {
                        return [false, err];
                    }
                );
        }

        gapi.load("client", initiate);
    }

    const context = {
        eventAlertMessage,
        editedEvents,
        setEditedEvents,
        setEventAlertMessage,
        events,
        getEvents,
        setEvents,
        createEvent,
        populateFolder,
        attachImages,
        currentEvent,
        setCurrentEvent,
        getEventById,
        deleteEvent,
        onCreateEventSubmit,
        onEditEventSubmit,
        cancelEvent,
        modifyAllFollowing,
    }

    return (
        <EventContext.Provider value={context}>
            {children}
        </EventContext.Provider>
    );
};

export const useEventContext = () => {
    const context = useContext(EventContext);

    return context;
}
