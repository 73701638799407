import { useAuthContext } from '../../contexts/AuthContext';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

export const Login = () => {
    const { googleSignIn } = useAuthContext();

    googleSignIn();

    return (
        <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 3, mx: 5 }}>
            <Typography variant='body1'>Waiting for Google to Authenticate your Session..</Typography>
            <CircularProgress />
        </Box>
    );
};