// Functionality for getting the Man-Hours
export const getManHours = (items, service) => {
    return items
        ?.map(item => item?.manHours)
        ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            Number(service?.manHours)
        ) || ''
};

// Functionality for getting the Total Charge
export const getTotalCharge = (items, service) => {
    return items
        ?.map(item => item?.totalCharge)
        ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            Number(service?.totalCharge)
        ) || ''
};

// Functionality for getting the Hourly Rate
export const getHourlyRate = (items, service) => {
    return items
        ?.map(item => item?.hourlyRate)
        ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            Number(service?.hourlyRate)
        ) || ''
};

// Functionality for getting the Cleaners Rate
export const getCleanersRate = (items, service, manHours) => {
    return items
        ?.map(item => item?.cleanersRate)
        ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            Number(service?.cleanersRate)
        ) / (Number(manHours)) || ''
};

// Functionality for getting the Cleaners Pay
export const getCleanersPay = (items, service, team) => {
    return (items
        ?.map(item => item?.cleanersPay)
        ?.reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            Number(service?.cleanersPay)
        ) / (Number(team?.length) || 1)) || ''
};