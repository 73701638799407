import { Navigate, Outlet } from "react-router-dom";

import { useAuthContext } from "../../contexts/AuthContext";


export const RouteGuard = ({
    children,
}) => {
    const { session } = useAuthContext();

    if (!session) {
        return <Navigate to='/login' />
    }

    return children ? children : <Outlet />
};