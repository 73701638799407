import { useState, useEffect, createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { serviceServiceFactory } from '../services/serviceService';

export const ServiceContext = createContext();

export const ServiceProvider = ({
    children,
}) => {
    const navigate = useNavigate();
    const [services, setServices] = useState([]);
    const serviceService = serviceServiceFactory();
    const [serviceAlertMessage, setServiceAlertMessage] = useState('');

    useEffect(() => {
        serviceService.getAll()
            .then(result => {
                result?.services?.map(function (service) {
                    service.id = service._id
                    service.label = service.service
                    service.createdAt = new Date(service.createdAt).toLocaleString()
                    return service
                })
                setServices(result.services);
            });
        // eslint-disable-next-line
    }, []);

    const onCreateServiceSubmit = async (data) => {
        try {
            const createdService = await serviceService.create(data);
            createdService.id = createdService._id
            createdService.label = createdService.service
            createdService.createdAt = new Date(createdService.createdAt).toLocaleString()

            createdService.items = createdService?.items?.map(item => item._id)
            createdService.invoicingPlatform = createdService.invoicingPlatform._id

            setServices(state => [...state, createdService]);

            navigate(`/services`);
        } catch (error) {
            setServiceAlertMessage(error.message)
        }
    };

    const onEditServiceSubmit = async (values) => {
        try {
            const result = await serviceService.edit(values._id, values);

            setServices(state => state.map(x => x._id === values._id ? { ...result?.service, label: result?.service.service, id: result?.service?._id } : x));

            navigate(`/services`);
        } catch (error) {
            setServiceAlertMessage(error.message);
        }
    };

    const deleteService = async (serviceObject) => {
        await serviceService.delete(serviceObject._id)

        setServices(state => state.filter(service => service._id !== serviceObject._id));

        navigate(`/services`);
    };

    const getService = (serviceId) => {
        return services.find(service => service._id === serviceId);
    };

    const contextValues = {
        services,
        serviceAlertMessage,
        setServiceAlertMessage,
        onCreateServiceSubmit,
        onEditServiceSubmit,
        deleteService,
        getService,
    }

    return (
        <ServiceContext.Provider value={contextValues}>
            {children}
        </ServiceContext.Provider>
    );
};

export const useServiceContext = () => {
    const context = useContext(ServiceContext);

    return context;
}