import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';
import { useService } from '../../hooks/useService';

import EditIcon from '@mui/icons-material/Edit';
import {
    Autocomplete,
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
} from '@mui/material'

import { useWorkerContext } from '../../contexts/WorkerContext';
import { workerServiceFactory } from '../../services/workerService';
import { usePaperworkContext } from '../../contexts/PaperworkContext';
import { getPaperworks } from '../../utils/paperworkUtils';

export const EditWorker = () => {
    const { workerId } = useParams();
    const workerService = useService(workerServiceFactory);
    const { workerAlertMessage, setWorkerAlertMessage, onEditWorkerSubmit } = useWorkerContext();
    const { paperworks } = usePaperworkContext();

    const initialValues = {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        paperwork: [],
    };

    const [formErrors, setFormErrors] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        paperwork: '',
    });

    const { values, changeHandler, onSubmit, changeValues } = useForm(initialValues, onEditWorkerSubmit);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    useEffect(() => {
        workerService.getOne(workerId)
            .then(result => {
                changeValues({ ...result, paperwork: getPaperworks(result.paperwork, paperworks) });
            });
        // eslint-disable-next-line
    }, [workerId, paperworks]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <EditIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Edit Worker Details
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.firstName}
                        margin="normal"
                        required

                        error={formErrors.firstName}
                        helperText={formErrors.firstName}
                        fullWidth
                        id="firstName"
                        label="First Name"
                        name="firstName"
                        autoComplete="name"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.lastName}
                        margin="normal"
                        required
                        error={formErrors.lastName}
                        helperText={formErrors.lastName}
                        fullWidth
                        id="lastName"
                        label="Last Name"
                        name="lastName"
                        autoComplete="lastName"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.email}
                        margin="normal"
                        required
                        error={formErrors.email}
                        helperText={formErrors.email}
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.phoneNumber}
                        margin="normal"
                        required
                        error={formErrors.phoneNumber}
                        helperText={formErrors.phoneNumber}
                        fullWidth
                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                        id="phoneNumber"
                        label="Phone Number"
                        name="phoneNumber"
                        autoComplete="phone"
                    />

                    <Autocomplete
                        multiple
                        value={values.paperwork}
                        onChange={(event, newValue) => {
                            changeValues({ ...values, paperwork: newValue })
                        }}
                        options={paperworks}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                margin='normal'
                                {...params}
                                label="Paperwork"
                                placeholder="+ Add"
                            />
                        )}
                    />

                    {workerAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setWorkerAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {workerAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                    {/* <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2">
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>
                </Grid> */}
                </Box>
            </Box>
        </Container >
    );
};