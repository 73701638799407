import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { useForm } from '../../hooks/useForm';
import { useError } from '../../hooks/useError';
import { useService } from '../../hooks/useService';

import EditIcon from '@mui/icons-material/Edit';
import {
    Container,
    Typography,
    Box,
    Avatar,
    Button,
    CssBaseline,
    TextField,
    Alert,
    Snackbar,
    Autocomplete,
} from '@mui/material'

import { useVehicleContext } from '../../contexts/VehicleContext';
import { vehicleServiceFactory } from '../../services/vehicleService';
import { usePaperworkContext } from '../../contexts/PaperworkContext';
import { getPaperworks } from '../../utils/paperworkUtils';

export const EditVehicle = () => {
    const { vehicleId } = useParams();
    const vehicleService = useService(vehicleServiceFactory);
    const { vehicleAlertMessage, setVehicleAlertMessage, onEditVehicleSubmit } = useVehicleContext();
    const { paperworks } = usePaperworkContext();

    const initialValues = {
        brand: '',
        make: '',
        plates: '',
        paperwork: [],
    };

    const [formErrors, setFormErrors] = useState({
        brand: '',
        make: '',
        plates: '',
        paperwork: '',
    });

    
    const { values, changeHandler, onSubmit, changeValues } = useForm(initialValues, onEditVehicleSubmit);
    const { formValidate } = useError(formErrors, setFormErrors, values);

    
    useEffect(() => {
        vehicleService.getOne(vehicleId)
            .then(result => {
                changeValues({...result, paperwork: getPaperworks(result.paperwork, paperworks)});
            });
        // eslint-disable-next-line
    }, [vehicleId, paperworks]);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    marginBottom: 3,
                    paddingY: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <EditIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    Edit Vehicle Details
                </Typography>
                <Box component="form" onSubmit={onSubmit} noValidate sx={{ mt: 1 }}>

                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.brand}
                        margin="normal"
                        required
                        error={formErrors.brand}
                        helperText={formErrors.brand}
                        fullWidth
                        id="brand"
                        label="Brand"
                        name="brand"
                        autoComplete="name"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.make}
                        margin="normal"
                        required
                        error={formErrors.make}
                        helperText={formErrors.make}
                        fullWidth
                        id="make"
                        label="Make"
                        name="make"
                        autoComplete="make"
                    />
                    <TextField
                        onChange={changeHandler}
                        onBlur={formValidate}
                        value={values.plates}
                        margin="normal"
                        required
                        error={formErrors.plates}
                        helperText={formErrors.plates}
                        fullWidth
                        id="plates"
                        label="Plates"
                        name="plates"
                        autoComplete="plates"
                    />

                    <Autocomplete
                        multiple
                        value={values.paperwork}
                        onChange={(event, newValue) => {
                            changeValues({ ...values, paperwork: newValue })
                        }}
                        options={paperworks}
                        filterSelectedOptions
                        renderInput={(params) => (
                            <TextField
                                margin='normal'
                                {...params}
                                label="Paperwork"
                                placeholder="+ Add"
                            />
                        )}
                    />

                    {vehicleAlertMessage &&
                        <Snackbar
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                            open={() => console.log("open")}
                            autoHideDuration={6000}
                            onClose={() => setVehicleAlertMessage('')}
                        >
                            <Alert variant="filled" severity="error">
                                {vehicleAlertMessage}
                            </Alert>
                        </Snackbar>
                    }
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Send
                    </Button>
                    {/* <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">
                            Forgot password?
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link href="#" variant="body2">
                            {"Don't have an account? Sign Up"}
                        </Link>
                    </Grid>
                </Grid> */}
                </Box>
            </Box>
        </Container >
    );
};