import { requestFactory } from "./requester"

const baseUrl = process.env.REACT_APP_ENV_HERE === "production"
? 'https://titan-cleaners-be.onrender.com/api/clients'
: 'http://localhost:3005/api/clients'

export const clientServiceFactory = (token) => {
    const request = requestFactory();

    const getAll = async () => {
        const result = await request.get(`${baseUrl}`);
    
        return result.clients;
    };

    const getOne = async (clientId) => {
        const result = await request.get(`${baseUrl}/${clientId}`);

        return result.client;
    };

    const create = async (clientData) => {
        const result = await request.post(baseUrl, clientData);

        return result.client;
    };

    const edit = (clientId, data) => request.put(`${baseUrl}/${clientId}`, data);

    const deleteClient = (clientId) => request.delete(`${baseUrl}/${clientId}`); 

    return {
        getAll,
        getOne,
        create,
        edit,
        delete: deleteClient,
    };
};
