import { useState } from 'react';

import { changeMonth } from '../utils/dateUtils';
import { monthPeriods } from '../utils/mappers';

import { months } from "../utils/mappers";

export const usePeriods = () => {
    const [date, setDate] = useState(new Date());
    const [period, setPeriod] = useState(
        {
            timeMin: new Date(date.getFullYear(), date.getMonth(), 1),
            timeMax: new Date(date.getFullYear(), date.getMonth(), monthPeriods[date.getMonth()] + 1)
        }
    );

    const getNextMonth = () => {
        setDate(changeMonth(date, +1))
    }

    const getLastMonth = () => {
        setDate(changeMonth(date, -1))
    }

    const getThisMonth = () => {
        setDate(new Date())
    }

    const changePeriod = () => {
        setPeriod({
            timeMin: new Date(date.getFullYear(), date.getMonth(), 1),
            timeMax: new Date(date.getFullYear(), date.getMonth(), monthPeriods[date.getMonth()] + 1)
        })
    };

    return {
        period,
        date,
        monthName: months[(new Date()).getMonth() + 1],
        getNextMonth,
        getLastMonth,
        getThisMonth,
        changePeriod,
    };
}